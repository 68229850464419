
import {createContext, useReducer} from 'react';
import {
  getPromoData,
} from '../helpers/custom';

const initialState = {
  shipper: {
    member_id: '',
    name: '',
    phone_number: '',
    services: 'self_services',
    for_me: true
  },
  receiver: {
    name: '',
    phone_number: '',
    districts: '',
    address: '',
  },
  discount: {
    _type: 'fixed',
    amount: 0,
  },
  description: '',
  pickup_date: new Date().setDate(new Date().getDate()),
  pickup_time: '',
  payment_info: {
    cod: false,
    status: false,
    method: 'trf',
    amount: 0,
    change: 0,
  },
  orders: [],
}

const oldState = localStorage.getItem('pickup_date_state');
if(oldState) {
  initialState.pickup_date = oldState;
}else
  initialState.pickup_date = new Date(initialState.pickup_date).toISOString().replace(/T/, ' ').replace(/\..+/, '').split(' ')[0];

const OrderContext = createContext({
  data: initialState,
  onChange: ({
    name = '',
    value = '',
    payload = undefined,
  }) => {},
  updateOrders: ({
    type = 'update',
    id = '',
    key = 0,
    name = '',
    value = '',
    payload = '',
  }) => {},
  addOrder: (order = {}) => {},
  getExistsOrder: ({
    product_id,
    is_variation = false,
    variation_id,
  }) => {},
  clearState: _ => {},
})

function orderReducer (state, action) {
  switch (action.type) {
    case 'CLEAR':
      return {...state, data: {...initialState, pickup_date: new Date(initialState.pickup_date).toISOString().replace(/T/, ' ').replace(/\..+/, '').split(' ')[0], ...action.payload}};
    case 'ON_CHANGE':
      return {...state, data: {...state.data, ...action.payload}};
    default:
      return state;
  }
}

function OrderProvider (props) {
  const [state, dispatch] = useReducer(orderReducer, {getExistsOrder: _ => null, data: initialState, onChange: _ => null, clearState: _ => null, addOrder: _ => null, updateOrders: _ => null,}),
    onChange = ({
      name = '',
      value = '',
      payload = undefined,
    }) => {
      if(payload === undefined)
        return dispatch({
          type: 'ON_CHANGE',
          payload: {
            [name]: value,
          }
        })

      dispatch({
        type: 'ON_CHANGE',
        payload,
      })
    },
    addOrder = (order) => {
      let newData = {...state.data},
        getIndex = -1,
        getOneDuplicated = newData?.orders?.find((_order, _index) => {
          if(!_order.is_variation) {
            if(_order.product_id === order.product_id) {
              getIndex = _index;
              return true;
            }
            return false;
          }

          if(_order.product_id === order.product_id && order.variation_id === _order.variation_id && JSON.stringify(_order.modifiers) === JSON.stringify(order.modifiers)) {
            getIndex = _index;
            return true;
          }
          return false;
        });

      let payload = {...order};


      let newOrder = null,
        isUpdated = false,
        dismisPromo = false;

      if(payload.advance) {
        // 1. Jika duplikat = false;
        const getAllPromo = newData.orders?.filter(el => el.promo?._id === payload?.promo?._id);
        if(getAllPromo.length === 0) {
          // jika state belum ada data;
          const promoData = getPromoData(payload.promo, {qty: payload.qty, price: payload.price});
          if(promoData)
            payload.promo = promoData;
        }else {
          let getAmountFill = {
            index: -1,
            data: null,
          }, // ambil yg amount promonya udah diisi terus ubah di sini
            qty = payload.qty,
            lowerPrice = getAllPromo.sort((a, b) => a.price - b.price)[0];

          for(let i=0; i<getAllPromo.length; i++) {
            const _orderPromo = getAllPromo[i];
            qty += _orderPromo.qty;
            if(_orderPromo?.promo?.amount > 0)
              getAmountFill = {
                index: i,
                data: _orderPromo,
              };
          }

          let price = lowerPrice.price;
          if(price > payload.price)
            price = payload.price;

          if(getAmountFill.data && getAmountFill.index !== -1) {
            let indexUpdated = getAmountFill.index;
            const dataItem = (() => {
              let check = null
              for(let i=0; i<newData.orders.length; i++) {
                const el = newData.orders[i];
                if (el.product_id === getAmountFill.data.product_id && el.variation_id === getAmountFill.data.variation_id) {
                  check = el;
                  indexUpdated = i;
                }
              }
              return check
            })(),
            // const dataItem = newData.orders[getAmountFill.index],
            promoData = getPromoData(dataItem?.promo, {qty, price});
            if(promoData) {
              dismisPromo = true;
              newData.orders[indexUpdated].promo = promoData;
            }
            
          }else if(!getOneDuplicated){
            const promoData = getPromoData(payload.promo, {qty, price});
            if(promoData)
              payload.promo = promoData
            newData.orders = [payload, ...newData.orders]; // ?
          }
        }
      }

      if(getOneDuplicated && getOneDuplicated.product_id && getIndex !== -1) {
        if(JSON.stringify(order?.modifiers) === JSON.stringify(getOneDuplicated?.modifiers)) {
          newData.orders[getIndex].qty += order.qty;
          const orderItem = newData.orders[getIndex];
          if(orderItem.promo?._id && !dismisPromo) {
            const payloadPromo = getPromoData(orderItem.promo, {qty: orderItem.qty, price: orderItem.price});
            if(payloadPromo)
              newData.orders[getIndex].promo = payloadPromo;
          }
          isUpdated = true;
          newOrder = newData.orders
        }
      };

      dispatch({
        type: 'ON_CHANGE',
        payload: {
          orders: isUpdated ? newOrder : [payload, ...state.data.orders]
        }
      })
    },
    clearState = except => dispatch({type: 'CLEAR', payload: except}),
    updateOrders = ({
      type = 'update',
      id = '',
      name = '',
      value = '',
      key = -1,
      payload = '',
    }) => {
      if(type === 'delete') {
        let newData = {...state.data},
          isAdvance = false,
          promo_id = null,
          orders = newData.orders.filter((el, index) => {
            if(key !== index)
              return true;

            isAdvance = el.advance || false;
            if(el.promo && el.promo._id)
              promo_id = el.promo._id;
            return false;
          });

        if(isAdvance && promo_id) {
          const getAllPromo = orders?.filter(el => el.promo?._id === promo_id);
          if(getAllPromo.length > 0) {
            let qty = 0,
              getAmountFill = {
                index: -1,
                data: null,
              },
              filter = getAllPromo.sort((a,b) => a.price - b.price);

            const lowerPrice = filter[0];

            for(let j=0; j<filter.length; j++) {
              qty += filter[j].qty;
              if(filter[j].promo?.amount > 0){
                const get_index = orders.findIndex(el => {
                  if(filter[j].variation_id && el.variation_id && filter[j].variation_id === el.variation_id)
                    return true;
                  if(filter[j].product_id === el.product_id)
                    return true;
                  return false;
                })
                getAmountFill = {
                  index: get_index,
                  data: orders[get_index],
                }
              }
            }

            if(getAmountFill.index === -1 || getAmountFill.data === null) {

              getAmountFill = {
                index: 0,
                data: lowerPrice,
              }
            }

            let price = lowerPrice.price;
            
            const promoData = getPromoData(getAmountFill.data.promo, {qty, price});

            if(promoData)
              orders[getAmountFill.index].promo = promoData;


          }
        }

        newData.orders = orders;
        dispatch({
          type: 'ON_CHANGE',
          payload: newData,
        })
        return;
      }
      let newData = {...state.data};
      const getIndex = newData.orders.findIndex((el, index) => {
        return (el.product_id === id || el.variation_id === id) && (key !== -1 && key === index)
      });
      if(getIndex !== -1) {
        const orderItem = newData.orders[getIndex];
        let _payload = orderItem
        if(payload !== undefined) {
          _payload = {...orderItem, ...payload};
          newData.orders[getIndex] = _payload;
        }
        else {
          _payload[name] = value;
          newData.orders[getIndex] = _payload;
        }
        

        if(_payload.promo?._id) {
          if(_payload.advance) {
            const getAllPromo = newData.orders?.filter(el => el.promo?._id === _payload?.promo?._id);
            if(getAllPromo.length === 0) {
              const payloadPromo = getPromoData(_payload.promo, {qty: _payload.qty, price: _payload.price});
              if(payloadPromo)
                _payload.promo = payloadPromo;
            }else {
              let getAmountFill = {
                index: -1,
                data: null,
              },
                qty = 0,
                lowerPrice = getAllPromo.sort((a, b) => a.price - b.price)[0];

              for(let i=0; i<getAllPromo.length; i++) {
                const _orderPromo = getAllPromo[i];
                // if(_orderPromo.product_id !== _payload.product_id || (_orderPromo.variation_id && _orderPromo.variation_id === _payload.variation_id)){
                  qty += _orderPromo.qty;
                // }
                if(_orderPromo?.promo?.amount > 0)
                  getAmountFill = {
                    index: i,
                    data: _orderPromo,
                  }
              }

              let price = lowerPrice.price;
              if(price > _payload.price)
                price = _payload.price;

              if(getAmountFill.data && getAmountFill.index !== -1) {
                let indexUpdated = getAmountFill.index;
                const dataItem = (() => {
                  let check = null;
                  for(let i=0; i<newData.orders.length; i++) {
                    const el = newData.orders[i];
                    if(el.product_id === getAmountFill.data?.product_id && el.variation_id === getAmountFill.data?.variation_id) {
                      check = el;
                      indexUpdated = i;
                    }
                  }
                  return check;
                })(),
                promoData = getPromoData(dataItem?.promo, {qty, price});

                if(promoData)
                  newData.orders[indexUpdated].promo = promoData;
              }else {
                const payloadPromo = getPromoData(_payload.promo, {qty, price});
                if(payloadPromo)
                  _payload.promo = payloadPromo;
              }
            }
          }else {
            const payloadPromo = getPromoData(_payload.promo, {qty: _payload.qty, price: _payload.price});
            if(payloadPromo)
              _payload.promo = payloadPromo;
          }
        }

        newData.orders[getIndex] = _payload;

        dispatch({
          type: 'ON_CHANGE',
          payload: newData,
        })
      }
    },
    getExistsOrder = ({
      product_id,
      // is_variation = false,
      variation_id,
    }) => {
      const getExists = state.data?.orders?.filter(order => {
        if(variation_id) {
          return order.product_id === product_id && order.variation_id === variation_id;
        }

        return order.product_id === product_id
      })

      return getExists;
    }

  return (
    <OrderContext.Provider
      value={{...state, addOrder, clearState, onChange, updateOrders, getExistsOrder}}
      {...props}/>
  )
}

export {OrderProvider, OrderContext}