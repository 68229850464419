import axios from 'axios';

export const gtw = axios.create({
  // baseURL: 'http://192.168.18.192:5008',
  baseURL: process.env.REACT_APP_GTW_URL || 'localhost',
  timeout: 60000,
})

export const apiAddress = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
  timeout: 40000,
})