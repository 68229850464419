import {
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import x from 'axios';
import {gtw} from './apis';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {handleError, useCancelRequest} from './helpers';
import {AuthContext} from '../utils/auth';
import {
  convertDataToQuery,
  getQueryUrl,
} from '../helpers/custom';

const baseUrl = '/order';

export const useCreateOrder = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = async function (payload, callback = (error, result) => null) {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'post',
          url: `${baseUrl}`,
          data: payload,
          headers,
          cancelToken: _req.token,
        });
        callback(null, data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message, null);
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    loading,
    onProcess,
  }
}

export const useRateOrder = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = async (order_id, payload, callback = (error, result) => null) => {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'patch',
          url: `${baseUrl}/rating/${order_id}`,
          data: payload,
          cancelToken: _req.token,
          headers,
        });

        callback(null, data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message, null);
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    onProcess,
    loading,
  }
}


export const useCancelOrder = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {order_id} = useParams(),
    {headers} = useContext(AuthContext),
    onProcess = async function (payload, callback = (error, result) => null, forceOrderId) {
      setLoading(true);
      try {
        if(!payload?.reason)
          throw new Error('Alasan wajib diisi');
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'patch',
          url: `${baseUrl}/cancel/${forceOrderId || order_id}`,
          data: payload,
          headers,
          cancelToken: _req.token,
        });
        callback(null, data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message, null);
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    loading,
    onProcess,
  }
}



export const useListOrders = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {search} = useLocation(),
    push = useNavigate(),
    {headers} = useContext(AuthContext),
    [error, setError] = useState(''),
    [offset, setOffset] = useState(0),
    [data, setData] = useState({count: 0, orders: []}),
    query = useMemo(() => {
      if(!search)
        return {search: ''};
      const _query = getQueryUrl(search);
      if(_query.start && _query.start ==='0')
        setOffset(0);
      delete _query.start;
      return _query;
    }, [search]),
    changeState = payload => {
      setOffset(0);
      push(`/order${convertDataToQuery({...query, ...payload})}`)
    },
    onProcess = useCallback(async function () {
      setLoading(true);
      setError('');
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          let _query = {
            order_by: 'createdAt',
            sort_by: 'desc',
            ...query,
            offset,
            limit: 10,
          }
          const {data: _data} = await gtw({
            method: 'get',
            url: `${baseUrl}${convertDataToQuery(_query)}&field=payment_info,book_id,total_payment,pickup_date,pickup_time,description,createdAt,updatedAt,_system`,
            headers,
            cancelToken: _req.token,
          });
          if(offset === 0)
            return setData(_data);
          setData(prev => ({
            ...prev,
            orders: [...prev.orders, ..._data.orders || []],
          }));
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;

          setError(message);
        }
      })();

      if(cancel)
        return;
      setLoading(false);
    }, [offset, headers, query]),
    updateOrder = (order_id, payload) => {
      setData(prev => ({
        ...prev,
        orders: prev.orders.map(order => {
          if(order._id === order_id)
            return {...order, ...payload};
          return order;
        })
      }))
    },
    hasMore = useMemo(() => {
      if(data && data.count) {
        const countMore = Math.floor(data.count / 10);
        return  parseInt(offset, 10) < countMore;
      }
      return false;
    }, [offset, data])

  useCancelRequest(request);

  return {error, loading, hasMore, offset, setOffset, updateOrder, onProcess, data, query, changeState};
}

export const useListOrderId = _ => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    [error, setError] = useState(null),
    [data, setData] = useState(null),
    {order_id} = useParams(),
    replace = _data => _data && _data._id ? setData(_data) : null,
    onProcess = useCallback(async function () {
      setLoading(true);
      setError(null);
      setData(null);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await gtw({
          method: 'get',
          url: `${baseUrl}/${order_id}`,
          headers,
          cancelToken: _req.token,
        });
        setData(_data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [headers, order_id]),
    uploadUrl = async function (url, callback = error => null) {
      try {
        if(!data)
          throw new Error('Silahkan muat ulang halaman');
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await gtw({
          method: 'patch',
          url: `${baseUrl}/url/${data?._id}`,
          headers,
          cancelToken: _req.token,
          data: {url},
        });
        if(_data && _data._id) {
          setData(_data);
          return callback(null);
        }
        callback('server sedang sibuk silahkan coba beberapa saat lagi');
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message);
      }
    }

  useCancelRequest(request);
  return {
    data,
    loading,
    error,
    replace,
    uploadUrl,
    onProcess,
  }
}

