
import {createContext, useCallback, useReducer} from 'react';

const ProductContext = createContext({
  products: [],
  clearCache: _ => null,
  addProduct: product => null,
})

function productRecuder (state, action) {
  switch (action.type) {
    case 'CLEAR_CACHE':
      return {...state, products: []};
    case 'ADD_PRODUCT':
      return {...state, products: [...state.products, action.payload]};
    default:
      return state;
  }
}

function ProductProvider (props) {
  const [state, dispatch] = useReducer(productRecuder, {products: [], clearCache: _ => null, addProduct: _ => null}),
    addProduct = useCallback(payload => {
      dispatch({
        type: 'ADD_PRODUCT',
        payload,
      })
    }, [dispatch]),
    clearCache = _ => dispatch({
      type: 'CLEAR_CACHE',
    });

  return (
    <ProductContext.Provider
      value={{...state, addProduct, clearCache}}
      {...props}/>
  )
};

export {
  ProductContext,
  ProductProvider,
}