import {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import {
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Input,
  InputAdornment,
  Stack,
  Alert,
  Rating,
  IconButton,
  Box,
  Modal,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  Card,
  CardMedia,
  CircularProgress,
  MenuItem,
  Select,
  Button,
  TextareaAutosize,
} from '@mui/material';
import {
  red,
} from '@mui/material/colors';
import {
  VisibilityOff,
  SearchOutlined,
  DeleteForeverOutlined,
  ShoppingCart,
  Star,
  Visibility,
} from '@mui/icons-material';
import {
  convertPrice,
} from '../helpers/custom';
import {
  useCancelOrder,
  useRateOrder,
} from '../services/order';
import { OrderContext } from '../utils/order';

export const Logo = ({noFlex, size}) => (
  <Typography
    variant={size === 'small' ? 'caption' : 'h5'}
    noWrap
    component="a"
    sx={{
      mr: 2,
      flexGrow: noFlex ? 0 : 1,
      fontFamily: 'monospace',
      fontWeight: 700,
      letterSpacing: '.3rem',
      color: 'inherit',
      textDecoration: 'none',
    }}>Member Elona</Typography>
)

export const ToogleButton = ({
  inputLabel = '',
  name,
  value,
  fullWidth = false,
  onChange = event => null,
  icon = {
    on: <Visibility/>,
    off: <VisibilityOff/>,
  },
  sxContainer = {},
}) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <FormControl fullWidth={fullWidth} sx={sxContainer} variant="standard">
      <InputLabel htmlFor="standard-adornment-password">{inputLabel}</InputLabel>
      <Input
        size='small'
        id="standard-adornment-password"
        name={name}
        type={showPass ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={_ => setShowPass(prev => !prev)}>
              {showPass ? icon.off : icon.on}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {
    xs: '79%',
    md: '50%',
  },
  maxWidth: '90%',
  maxHeight: {
    md: '90%',
    xs: '80%',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: 24,
  p: 4,
};

export const ElonaModal = ({
  open = false,
  boxStyle = {},
  title = '',
  mainContainer = {},
  onClose = _ => null,
  children,
}) => ((
  <Modal
    open={open}
    onClose={_ => onClose(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{...style, ...boxStyle}}>
      <Typography id="modal-modal-title" variant="h6" component="div">
        {title}
      </Typography>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start',...mainContainer}}>
        {children}
      </div>
    </Box>
  </Modal>
))




export const StackRowList = ({children, ...props}) => (
  <Stack direction='row' width='100%' alignItems='center' justifyContent='space-between' {...props}>
    {children}
  </Stack>
)

export const ButtonCancelOrder = ({
  getSpace = true,
  replace = newData => null,
  order_id,
}) => {
  const [confirm, setConfirm] = useState(false),
    {loading, onProcess} = useCancelOrder(),
    [res, setRes] = useState({status: '', message: ''}),
    [reason, setReason] = useState('');

  function onSureCancel () {
    setRes({status: '', message: ''});
    onProcess({reason}, (error, result) => {
      if(error)
        return setRes({status: 'error', message: error});

      if(result) {
        replace(result);
        setRes({status: 'ok', message: 'Pesanan berhasil dibatalkan'});
      }
    }, order_id)
  }
  return (
    confirm
      ? <StackRowList sx={getSpace ? {mt: 1, mb: 1} : {}} direction='column' alignItems='center' justifyContent='center'>
          <TextField
            value={reason}
            onChange={({target: {value}}) => setReason(value)}
            size='small'
            fullWidth
            sx={{mb: 2}}
            label='Alasan'
            variant='outlined'/>
        
          {loading
            ? <CircularProgress size={25}/>
            : <StackRowList justifyContent='space-around'>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={_ => setConfirm(false)}
                  color='success'>Ga jadi Batal</Button>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={onSureCancel}
                  color='error'>Ya Batal aja</Button>
              </StackRowList>}

          {res.status && res.message
            ? <Alert severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
            : null}

        </StackRowList>
      : <Button
          sx={getSpace ? {mt: 1} : {}}
          size='small'
          onClick={_ => setConfirm(true)}
          color='error'
          variant='outlined'>Batalkan Pesanan</Button>
  )
}


export const FormSelect = ({
  label = '',
  value = '',
  fullWidth = false,
  sx = {},
  className = '',
  onChange = _ => null,
  menus = [{value: '', view: ''}],
}) => {

  return (
    <FormControl className={className} fullWidth={fullWidth} sx={{mt: 1, ...sx}}>
      <InputLabel id='idPayment'>{label}</InputLabel>
      <Select labelId='idPayment' variant='standard' size='small' sx={{mt: 1}} label='Metode Pembayaran' value={value} onChange={onChange}>
        {menus.length > 0
          ? menus.map((menu, index) => <MenuItem
            key={index}
            value={menu.value}>{menu.view}</MenuItem>)
          : <MenuItem value=''>Belum ada Opsi</MenuItem>}
      </Select>
    </FormControl>
  )
}


export const MediaProductCard = ({
  product = {},
  onAddNonVariant = _ => null,
  onPush = product_id => null,
  isToday = true,
  joyride = false,
}) => {
  const stock = useMemo(() => {
    let _s = product.stock || 0,
      maxStock = 0;
    if(product.is_variation && product.variations.length > 0) {
      const variantStocks = product.variations.map(el => el.stock).sort((a,b) => a-b);
      _s = variantStocks[0];
      maxStock = variantStocks[variantStocks.length - 1];
    }
    return product.is_variation && product.variations.length > 0
      ? `${_s} - ${maxStock}`
      : _s === 0 ? 'Habis' : _s;
  }, [product]),
    {data: orderUtils} = useContext(OrderContext),
    disabled = useMemo(() => {
      if(!product?.is_variation) {
        const _data = orderUtils.orders.find(el => el.product_id === product.product_id && !el.is_variation);
        if(_data?.qty >= product.stock)
          return true;
      }
      return false;
    }, [product, orderUtils]);

  return (
    <Card sx={{width: '45%', m: 1}}>
      <CardHeader
        avatar={
          <Avatar sx={{
            bgcolor: red[500],
            display: {
              xs: 'none',
              md: 'flex'
            }
          }} aria-label="recipe">
            {product?.name[0]?.toUpperCase() || 'A'}
          </Avatar>
        }
        className={
          joyride
            ? 'product-name-joyride'
            : ''
        }
        title={product?.name || '-'}
        titleTypographyProps={{
          textTransform: 'uppercase',
          fontWeight: 'bolder',
        }}
        subheader={
          product.promo && product.promo._id && product.promo.is_active
            ? `Promo ${product?.promo?.name || '-'}`
            : ''
        }
        subheaderTypographyProps={{
          color: 'green',
          variant: 'caption',
          className: 'product-promo-joyride',
          fontSize: {
            xs: 10,
            md: 17,
          }
        }}
      />
      <CardMedia
        sx={{height: 140}}
        image={product.images?.length > 0 ? product.images[0] : '/images/defaultImages.jpeg'}
        title="green iguana"
      />
      <CardContent>
        <Typography className='product-stock-joyride' sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          ada {product?.variations?.length || 0} variant &nbsp; Stock: {stock}
        </Typography>
        <Typography variant='h5' className='product-price-joyride' sx={{ mb: 1.5 }} color="text.primary" fontWeight='bolder'>
          {convertPrice(product.price || '0')}
        </Typography>
        <Typography variant="body2" className='product-desc-joyride'>
          {product.description || '-'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant='outlined' onClick={_ => onPush(product.product_id)} className='product-select-joyride'>Pilih</Button>
        {!product.is_variation || product.variations?.legnth === 0 || joyride
          // ? <Button className='button-non-variant-joyride' onClick={_ => onAddNonVariant(product)} disabled={!isToday ? false : product.stock < 1 ? true : disabled} color='secondary' variant='outlined' size='small' endIcon={<ShoppingCart color={isToday && product.stock < 1 ? 'disabled' : 'secondary'} size={25}/>}>
          ? <Button className='button-non-variant-joyride' onClick={_ => onAddNonVariant(product)} color='secondary' variant='outlined' size='small' endIcon={<ShoppingCart color='secondary' size={25}/>}>
              {disabled ? `Max ${product.stock}` : 'Masuk'}
            </Button>
          : null}
      </CardActions>
    </Card>
  );
}


export const MolaInputSearch = ({
  title = '',
  changeState = value => null,
  fullWidth = false,
  className = '',
  sx={},
  valueState = '',
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueState);
  }, [valueState])

  return (
    <FormControl className={className} sx={{m: 'auto', mt: 1, mb: 1, width: fullWidth ? '100%' : undefined}} variant='standard'>
      <InputLabel>{title}</InputLabel>
      <Input
        size='small'
        type='text'
        value={value}
        onKeyUp={({key}) => {
          if(key === 'Enter')
            changeState(value);
        }}
        onChange={({target: {value}}) => setValue(value)}
        endAdornment={
          <InputAdornment position='end'>
            {valueState && value && value === valueState
              ? <IconButton
                  size='small'
                  onClick={_ => changeState('')}>
                  <DeleteForeverOutlined color='error'/>
                </IconButton>
              : <IconButton
                  size='small'
                  onClick={_ => changeState(value)}>
                  <SearchOutlined/>
                </IconButton>}
          </InputAdornment>
        }/>
    </FormControl>
  )
}


export const getPromoData = (dataPromo, product = {qty: 0, price: 0}) => {
	try {
		const is_multiple = dataPromo.is_multiple !== undefined ? dataPromo.is_multiple : true;
		if(!dataPromo.is_active)
			return undefined;
		const amount = (() => {
			if(dataPromo._type === 'buy-free') {
				const klipatan = dataPromo.qty + dataPromo.reward,
					calculate = Math.floor((product.qty / klipatan)) * dataPromo.reward;
				
				return is_multiple
					? calculate * product.price
					: calculate > 0
						? (dataPromo.reward * product.price)
						: 0;
			}
			if(dataPromo._type === 'buy-fix') {
				const calculate = Math.floor(product.qty / dataPromo.qty);
				return is_multiple // jika berlaku klipatan
					? calculate * dataPromo.reward // min order * total reward
					: calculate > 0 // cek min order
						? dataPromo.reward // jika memenuhi min order baru dapat reward
						: 0;
			}
	
			return dataPromo.reward;
		})()
	
		return {
			_id: dataPromo._id,
			_type: dataPromo._type,
			qty: dataPromo.qty,
			is_active: dataPromo.is_active || false,
			reward: dataPromo.reward,
			name: dataPromo.name,
			description: dataPromo.description,
			is_multiple,
			amount,
		}
	}catch(e) {}
	return undefined
}


export const ModifierItem = ({index, modifier, onChangeModifier = (modifier_id, option_id, payload, only_one) => null, limit_modifier = 5}) => {
  const disable_item = useMemo(() => {
    return limit_modifier === modifier.options?.filter(el => el.isChecked).length && modifier.has_limit;
  }, [limit_modifier, modifier]),
    [check_description, setCheckDescription] = useState([]);

  useEffect(() => {
    setCheckDescription(modifier.options.filter(el => el.isChecked && el.has_description).map(el => el));

  }, [modifier])

  const ButtonFunc = ({option}) =>
    <Button
      disabled={disable_item && !option.isChecked}
      variant='outlined'
      color='secondary' size='small'
      className={(option.default || option.isChecked) ? 'active' : ''}
      sx={{m: 0.5}}
      key={index}
      onClick={_ => onChangeModifier(modifier._id, option._id, {isChecked: option.isChecked ? false : true}, modifier.only_one)}>
      {option.name || '-'} {option.price ? <><br/> {convertPrice(option.price)}</> : null}
    </Button>
  return (
    <Stack
      display='flex'
      flexDirection='column'
      sx={{
        mt: 1,
      }}>
      <Typography variant='subtitle2'>{modifier.identity || '-'} {modifier.has_limit ? <>&nbsp; Limit: {limit_modifier} item</> : null}</Typography>

      {modifier.options?.length > 0
        ? <Stack
            width='100%'
            display='flex'
            flexDirection='row'
            flexWrap='wrap'>
            {modifier.options.map((option, index) => (
              <ButtonFunc key={index} option={option}/>
              // option.has_description
              //   ? <div className='description' key={index}>
              //       <ButtonFunc option={option}/>
              //     </div>
              //   : <ButtonFunc key={index} option={option}/>
            ))}
          </Stack>
        : null}
      {check_description?.length > 0
        ? check_description.map((desc, index) => (
          <TextField size='small' multiline rows={3} sx={{mt: 0.9}} onChange={({target: {value}}) => onChangeModifier(modifier._id, desc._id, {description: value})} value={desc.description || ''} label={`Deskripsi ${desc.name || ''}`} key={index}/>
        ))
        : null}
    </Stack>
  )
}


export const validatorQtyOrder = ({
  getOrders = [],
  isToday = true,
  productStock = 0,
  qtyBuy = 0,
}) => {

  let messageError = 'Jumlah yang di masukan melebihi stock yang tersedia';

  if(qtyBuy > productStock && isToday)
    return {
      status: 'error',
      message: messageError,
    };


  let _qty = qtyBuy;
  if(getOrders.length > 0) {
    for(let order of getOrders)
      _qty += order.qty;
  }
  if(productStock < _qty && isToday) {
    return {
      status: 'error',
      message: messageError,
    }
  }

  return {
    status: 'ok',
    message: '',
  }

}


// untuk sementara di tunda dlu 

// export const FinishOrder = () => {
//   const [confirm, setConfirm] = useState(false);

//   if(confirm)
//     return (
//       <StackRowList alignItems='center' justifyContent='center' direction='column'>
//         <Typography fontWeight='bolder' variant='subtitle2'>Action ini tidak bisa di Ulang, Pesanan udah diterima ?</Typography>
//         <StackRowList justifyContent='space-around'>
//           <Button
//             size='small'
//             onClick={_ => setConfirm(false)}
//             variant='outlined'
//             color='warning'>
//             Belum
//           </Button>
//           <Button
//             size='small'
//             variant='outlined'
//             color='success'>
//             Sudah
//           </Button>
//         </StackRowList>
//       </StackRowList>
//     )

//   return (
//     <Button
//       size='small'
//       color='success'
//       variant='outlined'
//       onClick={_ => setConfirm(true)}>Selesaikan Pesanan</Button>
//   )
// }

const labels = {
  1: 'Sangat Buruk',
  2: 'Buruk',
  3: 'Biasa Aja',
  4: 'Bagus',
  5: 'Sempurna',
};

export const GiveRating = ({
  order_id = '',
  readOnly = false,
  value: _value,
  replace = (newOrder) => null,
}) => {
  const [showRate, setShowRate] = useState(false),
    [value, setValue] = useState(0),
    {onProcess, loading} = useRateOrder(),
    [error, setError] = useState(''),
    [comment, setComment] = useState(''),
    description = useMemo(() => {
      if(value > 0) {
        return labels[value];
      }

      return '';
    }, [value]);

  function onSubmitRate () {
    setError('');
    const payload = {
      value,
      description,
      comment,
    }
    if(!payload.value)
      return setError('Pilih Rating bintang yang diinginkan');
    onProcess(order_id, payload, (error, result) => {
      if(result && result._id)
        return replace(result);

      return setError(error || 'Server sedang sibuk');
    })
  }

  useEffect(() => {
    if(_value) {
      setComment(_value?.comment);
      setValue(_value?.value);
    }
  }, [_value])


  if(readOnly)
    return (
      <StackRowList direction='column' justifyContent='center' alignItems='center'>
        <HoverRating
          value={value}
          readOnly/>
        <Typography variant='subtitle2'>{comment}</Typography>
      </StackRowList>
    )

  if(showRate)
    return (
      <StackRowList direction='column' justifyContent='center' alignItems='center'>
        <HoverRating
          value={value}
          setValue={setValue}/>

        <TextareaAutosize
          style={{
            width: '75%',
            marginTop: 10,
            height: 'auto',
            fontSize: '17px',
            marginBottom: 2,
          }}
          value={comment}
          minRows={3}
          maxRows={3}
          onChange={({target: {value}}) => setComment(value)}
          placeholder='Kritik & Saran jika diperlukan agar kami menjadi yang lebih baik'/>

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
        {loading
          ? <CircularProgress size={25}/>
          : <StackRowList sx={{mt: 1}} justifyContent='space-around'>
              <Button
                size='small'
                color='secondary'
                onClick={_ => setShowRate(false)}
                variant='outlined'>Ga Jadi</Button>

              <Button
                size='small'
                color='primary'
                onClick={onSubmitRate}
                variant='outlined'>Beri Rating</Button>
            </StackRowList>}
      </StackRowList>
    )

  return (
    <Button
      size='small'
      color='success'
      variant='outlined'
      onClick={_ => setShowRate(true)}>Beri Rating & Dapatkan Point</Button>
  )
}

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

function HoverRating({
  value = 0,
  setValue = _ => null,
  readOnly = false,
}) {

  return (
    <Box
      sx={{
        width: '50%',
        justifyContent: 'center',
        mb: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        name="feedback-orders"
        value={value}
        getLabelText={getLabelText}
        disabled={readOnly}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {value !== null && (
        <Box sx={{ ml: 2 }}>{labels[value]}</Box>
      )}
    </Box>
  );
}