import {
  useEffect,
  useContext,
} from 'react';
import {
  Stack,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  IconButton,
  Chip,
  Alert,
  Button,
} from '@mui/material';
import {
  Refresh,
  ArrowLeft,
} from '@mui/icons-material';
import {
  StackRowList,
} from '../../components/custom';
import {
  useGetAuthPoint,
} from '../../services/auth';
import {
  AuthContext,
} from '../../utils/auth';
import {
  convertPrice,
  getLocalDate,
} from '../../helpers/custom';
import {
  useList,
} from '../../services/history';
import {
  useNavigate,
} from 'react-router-dom';



const HistoryPage = _ => {
  const onProcess = useGetAuthPoint(),
    {data, loading, onProcess: _getHistory, error} = useList(),
    push = useNavigate(),
    {auth} = useContext(AuthContext);

  useEffect(() => {
    onProcess();
  }, [onProcess])

  useEffect(() => {
    _getHistory();
  }, [_getHistory])

  return (
    <Stack
      width='100%'
      height='92vh'
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        overflow: 'auto',
        bgcolor: '#A0E9FF',
        pt: 12
      }}>
      <StackRowList width='95%' sx={{bgcolor: 'white', p: 0.5, mb: 1, mt: 1}}>
        <Button
          size='small'
          variant='outlined'
          color='info'
          startIcon={<ArrowLeft/>}
          onClick={_ => push(`/member`)}>
          Kembali
        </Button>
      </StackRowList>
      <Stack width='95%' sx={{backgroundColor: 'white', p: 0.5, mb: 7}} direction='row' justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='column' justifyContent='center'>
          <Typography variant='subtitle2'>Point Anda</Typography>
          <Typography variant='h4'>{convertPrice(auth.current_point, true)}</Typography>
        </Stack>

        <StackRowList justifyContent='flex-end' width='25%'>
          <Chip
            sx={{
              fontWeight: 'bolder',
              textTransform: 'uppercase',
              bgcolor:
                auth.class === 'silver'
                  ? '#C0C0C0'
                  : auth.class === 'gold'
                    ? '#FFD700'
                    : auth.class === 'platinum'
                      ? '#E5E4E2'
                      : 'white',
            }}
            label={auth?.class}/>
          <IconButton
            size='small'
            onClick={onProcess}>
            <Refresh color='primary'/>
          </IconButton>
        </StackRowList>
      </Stack>

      <Stack
        width='95%'
        sx={{backgroundColor: 'white', p: 0.5}}
        direction='column'
        alignItems='center'>
        {data.histories?.length > 0
          ? data.histories.map((history, index) => (
            <HistoryCard history={history} index={index} key={history._id || index}/>
          ))
          : <Alert severity='warning'>Belum ada history</Alert>}

        {loading
          ? <LinearProgress sx={{width: '100%'}}/>
          : null}

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
      </Stack>
    </Stack>
  )
}

const HistoryCard = ({
  history = {},
  index = 0
}) => {

  return (
    <Card sx={{ width: '100%', mt: index !== 0 ?  1.5  : 0}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {getLocalDate(history.createdAt, true)}
        </Typography>
        <Typography variant="h5" component="div" color='green'>
          {history.operate || '+'} {convertPrice(history?.point || 0, true)} &nbsp; ({history.book_id || '-'})
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Total Belanja &nbsp; {convertPrice(history?.total_payment || 0, true)}
        </Typography>
        <Typography variant="body2">
          Point terakhir {convertPrice(history?.last_point || 0, true)}  <b style={{color: history.operate === '+' ? 'green' : 'red'}}>{history.operate} {convertPrice(history.point || 0, true)}</b><br/>
        </Typography>
        <Typography variant='h6' color='blue'>
          POINT AKHIR {convertPrice(history?.current_point || 0, true)}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default HistoryPage