import {
  useEffect,
  useMemo,
  Fragment,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Stack,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  Alert,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  ArrowLeft,
  FolderOpenOutlined,
} from '@mui/icons-material';
import {
  convertPrice,
  getLocalDate,
  getViewableStatus,
} from '../../helpers/custom';
import {
  StackRowList,
  ButtonCancelOrder,
  ElonaModal,
  GiveRating,
} from '../../components/custom';
import {
  useListOrderId,
} from '../../services/order';
import {
  useUpload,
} from '../../services/upload';

const PaymentView = ({
  onClose = _ => null,
  order,
  uploadUrl = (url, callback = (error) => null) => null,
}) => {
  const [confirm, setConfirm] = useState(false),
    [image, setImage] = useState(null),
    [loading, setLoading] = useState(false),
    [res, setRes] = useState({status: '', message: ''}),
    {loading: loadUpload, onProcess: upload} = useUpload();


  function getPhoto (event) {
    const files = event.target?.files[0];

    setImage(files);
  }

  function onSubmit () {
    upload(image, (errors, url) => {
      if(errors)
        return setRes({status: 'error', message: errors});

      setLoading(true);
      uploadUrl(url, (error) => {
        setLoading(false);
        if(error)
          return setRes({status: 'error', message: error});

        setConfirm(false);
      })
    })
  }

  return (
    <ElonaModal
      open
      onClose={onClose}
      boxStyle={{
        width: '80%',
        msxHieght: '85%',
        overflow: 'auto',
      }}>
      <Typography variant='h5'>Pembayaran {convertPrice(order?.total_payment || '0')}</Typography>

      <StackRowList sx={{mt: 2}}>
        <Typography variant='h5'>BCA</Typography>
        <Typography variant='h5' color='green' fontWeight='bolder'>
          8570105028
        </Typography>
      </StackRowList>
      <StackRowList sx={{mt: 1}}>
        <Typography variant='h5'>Mandiri</Typography>
        <Typography variant='h5' color='green' fontWeight='bolder'>
          1130017361126
        </Typography>
      </StackRowList>

      {order?._system?.expire_date && !order?.payment_info?.url
        ? <Typography variant='subtitl2' color='red'>
            Pesanan akan batal otomatis jika tidak dibayarkan sampai:
            {getLocalDate(order?._system.expire_date, true, true)}
          </Typography>
        : null}

      {order?.payment_info?.url
        ? <StackRowList direction='column' sx={{mt: 2}}>
            <img alt='bukti-transfer' src={order.payment_info?.url} style={{height: 200, width: 'auto'}}/>
            <Typography sx={{mt: 2}} color='green' variant='subtitl1' fontWeight='bolder'>
              Kami akan Review Bukti transfer secepatnya ya
            </Typography>
          </StackRowList>
        : !confirm
          ? <Button
              fullWidth
              size='large'
              variant='outlined'
              sx={{mt: 3}}
              onClick={_ => setConfirm(true)}>
              Pesanan Sudah Saya Bayar
            </Button>
          : <StackRowList direction='column' alignItems='center'>
              {image
                ? <img style={{height: 150, padding: 2}} src={URL.createObjectURL(image)} alt='payment-info'/>
                : <div className={`molas-file`}>
                    <label>Upload Click</label>
                    <form className="molas-file__form">
                      <input type="file" 
                      onChange={getPhoto}/>
                      <FolderOpenOutlined/>
                    </form>
                  </div>}
              {image
                ? <Typography variant='caption' color='error'>Klik gambar untuk hapus</Typography>
                : null}

              {loadUpload || loading
                ? <CircularProgress size={25}/>
                : <Button size='small' fullWidth variant='outlined' sx={{mt: 2}} onClick={onSubmit}>Upload</Button>}

              {res.status && res.message
                ? <Alert severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
                : null}
            </StackRowList>}
    </ElonaModal>
  )
}

const OrderDetailID = _ => {
  const {
    data,
    loading,
    error,
    onProcess,
    replace,
    uploadUrl,
  } = useListOrderId(),
    push = useNavigate(),
    [payment, setPayment] = useState(false);

  useEffect(() => {
    onProcess();
  }, [onProcess])

  return (
    <div className="main-container home" style={{justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 12}}>
      {payment && data
        ? <PaymentView uploadUrl={uploadUrl} onClose={setPayment} order={data}/>
        : null}
      <Stack direction='column' width='95%' sx={{backgroundColor: 'white', p: 0.5, mb: 5}}>
        <Button
          size='small'
          startIcon={<ArrowLeft/>}
          variant='outlined'
          sx={{mb: 1, width: '25%'}}
          onClick={_ => push('/order?order_by=createdAt&sort_by=desc&search=')}>Kembali</Button>
        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
          <Stack flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>Detail Pesanan</Typography>
            <Typography variant='subtitle2'>{data?.book_id || 'Book ID belum terbit'}</Typography>
          </Stack>

        </Stack>
      </Stack>

      <Stack
        width='95%'
        sx={{backgroundColor: 'white', p: 0.5}}
        direction='column'
        alignItems='center'>
        <Stack direction='column' width='100%'>
          <StackRowList>
            <Typography variant='subtitle1'>Tanggal Pengambilan:</Typography>
            <Typography variant='subtitle1'>{data?.pickup_date ? getLocalDate(data.pickup_date, true) : '-'}</Typography>
          </StackRowList>
          <StackRowList>
            <Typography variant='subtitle1'>Tanggal dibuat:</Typography>
            <Typography variant='subtitle1'>{data?.createdAt ? getLocalDate(data.createdAt, true, true) : '-'}</Typography>
          </StackRowList>
          <StackRowList>
            <Typography variant='subtitle1'>Terkahir Update:</Typography>
            <Typography variant='subtitle1'>{data?.updatedAt ? getLocalDate(data.updatedAt, true, true) : '-'}</Typography>
          </StackRowList>
          <StackRowList>
            <Typography variant='subtitle1'>Kode Booking:</Typography>
            <Typography variant='subtitle1' color='green'>{data?.book_id || 'Belum Terbit'}</Typography>
          </StackRowList>
          <StackRowList>
            <Typography variant='subtitle1'>Total Pembayaran:</Typography>
            <Typography variant='h6' fontWeight='bolder'>{convertPrice(data?.total_payment || '0')}</Typography>
          </StackRowList>
          <StackRowList>
            <Typography variant='subtitle1'>Status Pesanan:</Typography>
            <Typography variant='h6' fontWeight='bolder' color={data?._system?.status === 'batal' ? 'red' : 'green'}>{getViewableStatus(data?._system?.status)}</Typography>
          </StackRowList>
          {data?._system?.status !== 'batal'
            ? <StackRowList>
                <Typography variant='subtitle1'>Status Pembayaran:</Typography>
                <Typography variant='h6' fontWeight='bolder' color={data?.payment_info?.status ? 'green' : 'red'}>{data?.payment_info?.status ? 'Lunas' : data?._system?.status === 'request' ? 'Menunggu Approval' : 'Menunggu Pelunasan'}</Typography>
              </StackRowList>
            : <StackRowList justifyContent='flex-end'>
                <Typography variant='caption' color='red'>{data?._system?.reason}</Typography>
              </StackRowList>}
          {!data?.payment_info?.status && data?._system?.status === 'request'
            ? <StackRowList justifyContent='flex-end'>
                <Typography variant='caption' color='red'>Status ini disarankan untuk tidak melakukan pembayaran</Typography>
              </StackRowList>
            : null}
          {data?._system?.status === 'request'
            ? <ButtonCancelOrder replace={replace}/>
            : null}
          <Typography variant='caption' color='red'>
            {data?._system?.status === 'request'
              ? 'Admin akan respon dalam waktu paling lama 15 menit'
              : data?._system?.status === 'diterima'
                ? data?.payment_info?.url
                  ? 'Kami akan Review pesanan anda secepatnya'
                  : data?._system?.expire_date
                    ? `Harap melakukan pembayaran sebelum ${getLocalDate(data._system.expire_date, true, true)}`
                    : !data?.payment_info?.status
                      ? 'Harap segera melakukan pembayaran'
                      : ''
                : data?._system?.status.includes('batal')
                  ? 'Pesanan Batal'
                  : data?._system?.status === 'siap-dipickup'
                    ? 'Segera melakukan pengambilan, titik jemput maps *JAJAN ELONA*'
                    : (data?._system?.status === 'selesai' || data?._system?.status === 'on-the-spot')
                      ? 'Pesanan Selesai'
                      : 'Pesanan sedang diproses'}
          </Typography>
          {data?._system?.expire_date || !data?.payment_info?.status
            ? <StackRowList direction='column'>
                {/* <Typography variant='caption' color='red'>harap melakukan pembayaran sebelum {getLocalDate(data?._system?.expire_date, true, true)}</Typography> */}

                <Button
                  size='small'
                  variant='outlined'
                  sx={{mt: 0.5, mb: 0.5}}
                  fullWidth
                  onClick={_ => setPayment(true)}>Cara Pembayaran</Button>
              </StackRowList>
            : null}

          <StackRowList>
            <Typography variant='subtitle1'>Metode Pengambilan:</Typography>
            <Typography variant='subtitle1'>{data?.shipper?.services === 'self_services' ? 'Ambil Sendiri' : data?.shipper?.services === 'on-the-spot' ? 'Beli Langsung' : data?.shipper?.services}</Typography>
          </StackRowList>

          {(data?._system?.status === 'selesai' || data?._system?.status === 'on-the-spot') && data?._system?.rating?.value === 0
            ? <GiveRating
                order_id={data?._id}
                replace={replace}/>
            : data?._system?.rating?.value > 0
              ? <GiveRating
                  value={data?._system?.rating}
                  readOnly/>
              : null}

        </Stack>

        
        {loading
          ? <LinearProgress sx={{width: '100%'}}/>
          : null}

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
      </Stack>

      {data && data.orders?.length > 0
        ? <Stack
            width='95%'
            sx={{backgroundColor: 'white', p: 0.5, mt: 4, height: 'auto', overflow: 'auto'}}
            direction='column'
            alignItems='center'>
            <Typography variant='h6'>Detail Pesanan</Typography>
            {data.orders.map((order, index) => <OrderItem key={index} index={index} order={order}/>)}


            <StackRowList>
              <Typography variant='subtitle1'>Total Produk:</Typography>
              <Typography variant='subtitle1'>{convertPrice(data?.total_item_price + data?.total_modifier_price, true)}</Typography>
            </StackRowList>

            {data?.taxs?.length > 0
              ? data.taxs.map(tax => (
                <StackRowList key={tax?.tax_id}>
                  <Typography variant='subtitle1'>{tax?.name} {tax?._type === '%' ? `${tax?.value}%` : ''}</Typography>
                  <Typography variant='subtitle1'>{convertPrice(tax?.amount || '0', true)}</Typography>
                </StackRowList>
              ))
              : null}

            <StackRowList>
              <Typography variant='subtitle1'>Promo:</Typography>
              <Typography variant='subtitle1' color='red'>{convertPrice(data?.total_promo|| '0', true)}</Typography>
            </StackRowList>
            
            {data?.shipping_fee > 0
              ? <StackRowList>
                  <Typography variant='subtitle1'>Ongkos Kirim:</Typography>
                  <Typography variant='subtitle1' color='green' fontWeight='bolder'>{convertPrice(data.shipping_fee, true)}</Typography>
                </StackRowList>
              : null}

          </Stack>
        : null}
      
    </div>
  )
}


const OrderItem = ({
  order,
  index,
}) => {
  const modifiers = useMemo(() => {
    let modf = [];
    if(order.modifiers?.length > 0)
      modf = order.modifiers.map(el => ({
        _id: el._id,
        name: el.name,
        value: el.options.map(_el => _el.name),
        options: el.options.filter(_el => _el.description)?.map(map => ({name: map.name, description: map.description})),
      }))
    return modf;
  }, [order.modifiers])

  return (
    <Card sx={{width: '100%', mt: index !== 0 ? 0.5 : 0}}>
      <CardContent>
        <StackRowList>
          <Typography variant='caption'>
            {order.product_name || '-'}, {order.qty}x{convertPrice(order?.price || '0', true)}
          </Typography>
          <Typography variant='body2' fontWeight='bolder'>
            {convertPrice(order?.total_price || '0')}
          </Typography>
        </StackRowList>
        <StackRowList>
          <Typography variant='caption'>{order.description || '-'}</Typography>
          <Typography variant='caption' color='red'>
            {order?.promo
              ? `${order?.promo?.name || ''} ${order?.promo?.amount ? convertPrice(order?.promo?.amount || '0', true) : ''}`
              : null}
          </Typography>
        </StackRowList>
        {modifiers.map((modifier, _index) => (
          <Typography variant='caption' key={_index}>
            {modifier.name}  {modifier.value?.join(', ')}<br/>
            {modifier.options?.length > 0
              ? <Fragment>
                -  {modifier.options?.map((option, __index) => <b key={__index}>{option?.name} - {option?.description}</b>)}<br/>
                </Fragment>
              : null}
          </Typography>
        ))}
      </CardContent>
    </Card>
  )
}

export default OrderDetailID