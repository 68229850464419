import {
  ElonaNavigation,
} from './nav';
import './index.css';


const Layout = ({children}) => {
  return (
    <div id='layout-container'>
      <div className='container'>
        {children}
      </div>
      <ElonaNavigation/>
    </div>
  )
}

export default Layout;