import {
  useEffect,
  useMemo,
  useCallback,
  Fragment,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Stack,
  Alert,
  CircularProgress,
  LinearProgress,
  Typography,
  TextField,
} from '@mui/material';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  FormSelect,
  Logo,
  MediaProductCard,
  MolaInputSearch,
  getPromoData,
} from '../../components/custom';
import {
  useListCategories,
  useListProducts,
} from '../../services/products';
import FinalModal from '../../components/product';
import {
  OrderContext,
} from '../../utils/order';
import {
  ElonaJoyride,
} from '../../components/joyride';
import {
  JOYRIDE_CONSTANT,
} from '../../helpers/constant';

const HomePage = _ => {
  const {loading, data, onProcess, error} = useListCategories(),
    [run, setRun] = useState(true),
    {category} = useParams(),
    [steps] = useState([
      {target: '.pickup-date-joyride', event: 'click', disableBeacon: true, title: 'Tanggal Pengambilan', content: 'Sebelum pilih produk harap pilih tanggal terlebih dahulu ya'},
      {target: '.search-product-joyride', event: 'click', disableBeacon: true, title: 'Cari Produk', content: 'Cari Nama Produk yang kamu inginkan disini ya'},
      {target: '.kategory-joyride', event: 'click', disableBeacon: true, title: 'Kategory', content: 'Silahkan pilih kategory disini'},
      {target: '.product-name-joyride', event: 'click', disableBeacon: true, title: 'Nama Produk', content: 'Pilih berdasarkan nama produk disini'},
      {target: '.product-promo-joyride', event: 'click', disableBeacon: true, title: 'Promo', content: 'Promo tersedia disini'},
      {target: '.product-stock-joyride', event: 'click', disableBeacon: true, title: 'Stock', content: 'Stock yang tersedia produk ini'},
      {target: '.product-price-joyride', event: 'click', disableBeacon: true, title: 'Harga', content: 'Harga tercantum yang terendah dari variant'},
      {target: '.product-desc-joyride', event: 'click', disableBeacon: true, title: 'Deskripsi', content: 'Detail produk dilihat disini'},
      {target: '.product-select-joyride', event: 'click', disableBeacon: true, title: 'Tombol Detail', content: 'Untuk ke halaman detail produk'},
      {target: '.button-non-variant-joyride', event: 'click', disableBeacon: true, title: 'Tombol Keranjang', content: 'Jika produk tidak ada variasi, bisa langsung masuk keranjang'},
      {target: '.product-nav-joyride', event: 'click', disableBeacon: true, title: 'Halaman Utama', content: 'Halaman untuk memilih produk yang diinginkan'},
      {target: '.order-nav-joyride', event: 'click', disableBeacon: true, title: 'Pesanan', content: 'Histori order kamu berada disini'},
      {target: '.cart-nav-joyride', event: 'click', disableBeacon: true, title: 'Keranjang', content: 'Setelah pilih produk, silahkan checkout disini ya. semua aktifitas pemilihan produk tidak di record ya, sehingga jika menutup / refresh halaman produk yang kamu pilih akan hilang'},
      {target: '.profile-nav-joyride', event: 'click', disableBeacon: true, title: 'Profile', content: 'Lihat Profil kamu disini ya'},
    ]),
    push = useNavigate(),
    [nameLocalStorage] = useState(JOYRIDE_CONSTANT.homepage),
    selections = useMemo(() => {
      return decodeURIComponent(category);
    }, [category]);


  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <div className="main-container home" style={{justifyContent: 'flex-start', flexDirection: 'column'}}>
      {run
        ?<ElonaJoyride
            run={run}
            setRun={setRun}
            name={nameLocalStorage}
            steps={steps}/> : null}
      <Stack width='95%' sx={{backgroundColor: 'white', p: 0.5, mb: 3}} direction='row' justifyContent='space-between' alignItems='center'>
        <Logo size='small'/>
        {loading
          ? <CircularProgress size={20}/>
          : error
            ? <Alert severity="error">{error}</Alert>
            : <FormSelect className='kategory-joyride' sx={{width: '50%', textTransform: 'capitalize'}} value={selections} label='Pilih Kategory' onChange={({target: {value}}) => push(`/${value}`)} menus={[{value: 'all', view: 'Semua Kategory'}, ...data.categories.map(category => ({value: category.name, view: category.name}))]}/>}
      </Stack>

      <ListProduct run={run}/>
    </div>
  )
}

const ListProduct = ({
  run = true,
}) => {
  const {
    loading,
    error,
    data,
    query,
    hasMore,
    onProcess,
    changeState,
    setOffset,
  } = useListProducts(),
    observed = useRef(),
    [productid, setProductid] = useState(null),
    {addOrder, onChange, data: _order, clearState} = useContext(OrderContext),
    isToday = useMemo(() => {
      return _order.pickup_date === new Date().toISOString().split('T')[0];
    }, [_order.pickup_date]),
    lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, hasMore, setOffset]);

	function onHandleCallbackRefFetch (node) {
		if(loading) return;
		if(observed.current) observed.current.disconnect();
		observed.current = new IntersectionObserver(async entries => {
			if(entries[0].isIntersecting) {
				if(hasMore)
          setOffset(prev => prev + 1);
			}
		})

		if(node) observed.current.observe(node);
	}

  function onAddNonVariant (product) {

    const payload = {
      product_id: product.product_id,
      price: product.price,
      product_name: product.name || '-',
      qty: 1,
    }

    // const getOrders = getExistsOrder(payload);

    // let qty = 0;
    // if(getOrders.length > 0) {
    //   for(let order of getOrders) {
    //     qty += order.qty;
    //   }
    // }

    // if(product.stock <= qty && isToday)
    //   return;

    if(product.promo) {
      const advance = !['fix-amount', 'fix-%'].includes(product.promo?._type);
      if(advance) {
        payload.advance = true;
        payload.promo = {
          ...product.promo,
          amount: 0,
        }
      }else {
        const payloadPromo = getPromoData(isToday ? product.promo : {}, {qty: payload.qty, price: payload.price});
        if(payloadPromo)
          payload.promo = payloadPromo
      }
    }
    
    addOrder(payload, true);
  }

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <Stack width='95%' direction='column' alignItems='center' sx={{backgroundColor: 'white', p: 0.5}}>
      {productid
        ? <FinalModal
            onClose={_ => setProductid(null)}
            product_id={productid}/>
        : null}
      <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
        <TextField
          label='Tanggal Ambil'
          type='date'
          className='pickup-date-joyride'
          size='small'
          inputProps={{
            min: new Date().toISOString().split('T')[0],
          }}
          sx={{width: '45%'}}
          variant='standard'
          name='pickup_date'
          value={_order.pickup_date}
          onChange={({target}) => {
            let d = new Date();
            d.setHours(0,0,0,0);
            let b = new Date(target.value);

            if(Math.floor(+b / 1000) < Math.floor(+d / 1000))
              return;

            clearState();
            
            onChange({
              payload: {
                pickup_date: target.value,
                shipper: {
                  ..._order.shipper,
                  services: 'self_services',
                },
              }
            })
          }}/>
        <MolaInputSearch
          className='search-product-joyride'
          title='Cari Produk'
          valueState={query?.search}
          changeState={value => changeState({offset: 0, search: value})}/>
      </Stack>
      <Typography variant='caption' color='green'>*nb: Perhatikan Tanggal Pengambilan sudah sesuai</Typography>
      {!isToday
        ? <Typography variant='caption' color='red'>Bebas pilih produk tanpa melihat stock</Typography>
        : null}
      <Stack
        width='100%'
        sx={{backgroundColor: 'white', overflow: 'auto'}}
        direction='row'
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'>

        {run
          ? <MediaProductCard
              product={{
                is_variation: true,
                variations: [
                  {stock: 2, price: 5000},
                ],
                name: 'Roti Tawar',
                promo: {
                  _id: 'promo_id',
                  is_active: true,
                  name: 'Beli 2 Gratis 1'
                },
                images: [],
                stock: 2,
                price: 10000,
                description: 'Roti dengan bahan premium',
                product_id: 12345678910,
              }}
              joyride
              isToday
              onPush={_ => null}
              onAddNonVariant={_ => null}/>
          : data?.products.length > 0
            ? data.products.map((product, index) => <Fragment key={index}>
              <MediaProductCard
                product={product}
                isToday={isToday}
                onPush={setProductid}
                // onPush={product_id => push(`/${category}/${product_id}`, {state: {url: `/${category}${convertDataToQuery(query)}`}})}
                onAddNonVariant={onAddNonVariant}/>
              {index === data.products.length - 1 && data.count > (query?.limit || 10)
                ? <div ref={lastItemFetchRef}/>
                : null}
          </Fragment>)
          : error
            ? <Alert severity='error'>{error}</Alert>
            : <Alert severity='warning'>Belum ada Produk</Alert>}

        {loading
          ? <LinearProgress sx={{width: '100%'}}/>
          : null}

      </Stack>
    </Stack>
  )
}

export default HomePage