import {
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  CircularProgress,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardActions,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
  Button,
} from '@mui/material';
import {
  Delete,
  Cancel,
} from '@mui/icons-material';
import {
  ElonaModal,
  StackRowList,
  FormSelect,
} from './custom';
import {
  useSelectAddress,
  useCreateAddress,
  useDeleteAddress,
} from '../services/address';
import {
  AuthContext,
} from '../utils/auth';
import {
  getLocalDate,
} from '../helpers/custom';
import {
  decrypt,
} from '../helpers/crypto';


export const CreateAddress = ({
  onClose = _ => null,
  add = newData =>null,
}) => {
  const {
    provinces,
    cities,
    districts,
    wards,
    postalCode,
    data,
    error,
    loading,
    // clear,
    onChange,
    onGetProvince,
    onGetCities,
    onGetDistricts,
    onGetWards,
    onGetPostalCode,
  } = useSelectAddress(),
    [receiver, setReceiver] = useState({
      name: '',
      phone_number: '',
    }),
    {loading: _load, onProcess} = useCreateAddress(),
    [errorCreate, setErrorCreate] = useState(''),
    {auth} = useContext(AuthContext);

  useEffect(() => {
    onGetProvince();
  }, [
    onGetProvince,
  ])

  useEffect(() => {
    onGetCities();
  }, [
    onGetCities,
  ])

  useEffect(() => {
    onGetDistricts();
  }, [
    onGetDistricts,
  ])

  useEffect(() => {
    onGetWards();
  }, [
    onGetWards,
  ])


  useEffect(() => {
    onGetPostalCode();
  }, [
    onGetPostalCode
  ])





  function onChangeFormSelect (name) {
    return function ({target: {value}}) {
      try {
        onChange({
          target: {
            payload: {
              [name]: JSON.parse(value),
            }
          }
        })
      }catch(e) {}
    }
  }
  
  function onSubmit () {
    setErrorCreate('');
    const payload = {
      name: data?.name,
      province: data.province?.value,
      city: data.city?.value,
      districts: data?.districts?.value,
      wards: data.wards?.value,
      street: data?.street,
      postal_code: data.postal_code?.value,
      is_primary: data?.is_primary,
      receiver: {
        name: receiver?.name || auth.full_name,
        phone_number: receiver?.phone_number || auth.phone_number
      },
      gmap_info: data.gmap_info,
    };
    onProcess(payload, (error_, result) => {
      if(error_)
        return setErrorCreate(error_);

      add(result);
      onClose(false);
    })
  }
  return (
    <ElonaModal
      open
      boxStyle={{
        width: '80%',
        height: '85%',
        overflow: 'auto',
      }}
      onClose={onClose}>
      <StackRowList justifyContent='flex-end'>
        <IconButton
          color='error'
          onClick={_ => onClose(false)}
          >
          <Cancel/>
        </IconButton>
      </StackRowList>

      <TextField
        sx={{mt: 1, mb: 1}}
        value={receiver?.name}
        onChange={({target: {value}}) => setReceiver(prev => ({
          ...prev,
          name: value,
        }))}
        variant='standard'
        fullWidth
        label='Nama Penerima'/>

      <TextField
        sx={{mt: 1, mb: 1}}
        value={receiver?.phone_number}
        onChange={({target: {value}}) => setReceiver(prev => ({
          ...prev,
          phone_number: value,
        }))}
        variant='standard'
        fullWidth
        label='No Hp Penerima'
        placeholder='eg: 08xxxx'/>

      <TextField
        sx={{mt: 1, mb: 1}}
        value={data?.name}
        onChange={onChange}
        name='name'
        variant='standard'
        fullWidth
        label='Nama Alamat'
        placeholder='Kantor/Rumah/Toko'/>

      <TextField
        sx={{mb: 2}}
        value={data?.street}
        onChange={onChange}
        name='street'
        variant='standard'
        fullWidth
        label='Alamat Lengkap'
        placeholder='eg: Jl. Elona no 123'/>

      {loading && provinces.length === 0
        ? <CircularProgress size={25}/>
        : provinces.length > 0
          ? <FormSelect
              fullWidth
              label='Provinsi'
              onChange={onChangeFormSelect('province')}
              value={JSON.stringify(data?.province)}
              menus={[{value: JSON.stringify({id: '', value: ''}), view: 'Provinsi'}, ...provinces.map(el => ({view: el.value, value: JSON.stringify(el)}))]}/>
          : null}

      {loading && cities.length === 0
        ? <CircularProgress size={25}/>
        : cities.length > 0
          ? <FormSelect
              fullWidth
              sx={{
                mt: 2,
              }}
              label='Kota'
              onChange={onChangeFormSelect('city')}
              value={JSON.stringify(data?.city)}
              menus={[{value: JSON.stringify({id: '', value: ''}), view: 'Kota'}, ...cities.map(el => ({view: el.value, value: JSON.stringify(el)}))]}/>
          : null}

      {loading && districts.length === 0
        ? <CircularProgress size={25}/>
        : districts.length > 0
          ? <FormSelect
              fullWidth
              sx={{
                mt: 2,
              }}
              label='Kecamatan'
              onChange={onChangeFormSelect('districts')}
              value={JSON.stringify(data?.districts)}
              menus={[{value: JSON.stringify({id: '', value: ''}), view: 'Kecamatan'}, ...districts.map(el => ({view: el.value, value: JSON.stringify(el)}))]}/>
          : null}
      {loading && wards.length === 0
        ? <CircularProgress size={25}/>
        : wards.length > 0
          ? <FormSelect
              fullWidth
              sx={{
                mt: 2,
              }}
              label='Kelurahan'
              onChange={onChangeFormSelect('wards')}
              value={JSON.stringify(data?.wards)}
              menus={[{value: JSON.stringify({id: '', value: ''}), view: 'Kelurahan'}, ...wards.map(el => ({view: el.value, value: JSON.stringify(el)}))]}/>
          : null}

      {loading && postalCode.length === 0
        ? <CircularProgress size={25}/>
        : postalCode.length > 0
          ? <FormSelect
              fullWidth
              sx={{
                mt: 2,
              }}
              label='Kode Pos'
              onChange={onChangeFormSelect('postal_code')}
              value={JSON.stringify(data?.postal_code)}
              menus={[{value: JSON.stringify({id: '', value: ''}), view: 'Kode Pos'}, ...postalCode.map(el => ({view: el.value, value: JSON.stringify(el)}))]}/>
          : null}



      <TextField
        sx={{mt: 1, mb: 2}}
        value={data?.gmap_info}
        onChange={onChange}
        name='gmap_info'
        variant='standard'
        fullWidth
        label='Patokan GMAP / Link Maps'
        placeholder='eg: Jajan Elona / maps.google.com....'/>
      <StackRowList justifyContent='flex-end'>
        <FormControlLabel
          control={<Switch checked={data?.is_primary} onChange={({target: {checked}}) => onChange({target: {payload: {is_primary: checked}}})}/>}
          label='Jadikan Primary'/>
      </StackRowList>

      {_load
        ? <CircularProgress size={30}/>
        : <StackRowList justifyContent='space-around'>
            <Button
              size='small'
              variant='outlined'
              onClick={_ => onClose(false)}
              color='error'>Batal</Button>
            <Button
              size='small'
              variant='outlined'
              onClick={onSubmit}
              color='success'>Simpan</Button>
          </StackRowList>}

      {error || errorCreate
        ? <Alert severity='error'>{error || errorCreate}</Alert>
        : null}

    </ElonaModal>
  )
}



export const HistoryCard = ({
  address = {},
  remove = address_id => null,
  onChoose = _ => null,
  index = 0,
  viewOnly = false,
  disabled = false,
}) => {
  const receiver = useMemo(() => {
    try {
      if(address.receiver)
        return JSON.parse(decrypt(address.receiver));
    }catch(e) {}
    return {}
  }, [address.receiver]),
  [error, setError] = useState(''),
  [confirm, setConfirm] = useState(false),
  {onProcess,loading} = useDeleteAddress();

  return (
    <Card sx={{ width: '100%', mt: index !== 0 ?  1.5  : 0}}>
      <CardContent>
        <StackRowList>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {getLocalDate(address.createdAt, true)}
          </Typography>
          {address?.is_primary
            ? <Chip color='primary' label='Utama'/>
            : null}
        </StackRowList>
        <Typography variant="h5" component="div" color='green'>
          {address.name || '-'} [{address?.postal_code || '-'}]
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {address?.street ? decrypt(address?.street) : '-'}<br/>
          {address?.districts || '-'} - {address?.wards || '-'}, {address?.city || '-'} {address?.province || '-'}<br/>
          [{address?.gmap_info || '-'}]
        </Typography>
        <Typography variant="body2">
          Penerima<br/>{receiver?.name || '-'}  <b style={{color:'green'}}>+{receiver?.phone_number}</b><br/>
        </Typography>
      </CardContent>
      {!viewOnly
        ? <CardActions>
            <StackRowList justifyContent='flex-end'>
              {confirm
                ? loading
                  ? <CircularProgress size={35}/>
                  : <StackRowList justifyContent='flex-end'>
                      <Button
                        size='small'
                        onClick={_ => setConfirm(false)}
                        color='success'
                        endIcon={<Cancel/>}
                        sx={{mr: 2}}
                        variant='outlined'>
                        Ga Jadi
                      </Button>
                      <Button
                        size='small'
                        onClick={_ => onProcess(address?._id, _error => {
                          if(_error)
                            return setError(_error);

                          remove(address._id)
                        })}
                        color='error'
                        endIcon={<Delete/>}
                        variant='outlined'>
                        Hapus Saja
                      </Button>
                    </StackRowList>
                : <Button
                    size='small'
                    onClick={_ => setConfirm(true)}
                    color='error'
                    endIcon={<Delete/>}
                    variant='outlined'>
                    Hapus
                  </Button>}
            </StackRowList>
          </CardActions>
        : <CardActions>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              disabled={disabled}
              onClick={_ => onChoose({
                ...address,
                receiver,
              })}>
              {disabled
                ? 'Terpilih'
                : 'Pilih'}
            </Button>
          </CardActions>}

      {error
        ? <Alert severity='error' sx={{mt: 2}}>{error}</Alert>
        : null}
    </Card>
  )
}