import {
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  Stack,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  CardActions,
  Alert,
  Button,
} from '@mui/material';
import {
  convertPrice,
  getViewableStatus,
  getLocalDate,
} from '../../helpers/custom';
import {
  useListOrders,
} from '../../services/order';
import {
  MolaInputSearch,
  ButtonCancelOrder,
  FormSelect,
} from '../../components/custom';
import {
  useNavigate,
} from 'react-router-dom';


const HistoryOrderPage = _ => {
  const {
    data,
    loading,
    query,
    error,
    hasMore,
    setOffset,
    updateOrder,
    onProcess,
    changeState,
  } = useListOrders(),
    observed= useRef(),
    lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, hasMore, setOffset]);



function onHandleCallbackRefFetch (node) {
  if(loading) return;
  if(observed.current) observed.current.disconnect();
    observed.current = new IntersectionObserver(async entries => {
      if(entries[0].isIntersecting) {
        if(hasMore)
          setOffset(prev => prev + 1);
      }
    })

  if(node) observed.current.observe(node);
}

  useEffect(() => {
    onProcess();
  }, [onProcess])

  return (
    <div className="main-container" style={{justifyContent: 'flex-start', flexDirection: 'column'}}>
      <Stack width='95%' sx={{backgroundColor: 'white', p: 0.5, mb: 3, mt: 2}} direction='row' justifyContent='space-between' alignItems='center'>
        <Stack flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Pesanan Anda</Typography>
          <MolaInputSearch
            title='Cari Book Id'
            valueState={query?.search}
            changeState={search => changeState({search})}/>
        </Stack>

      </Stack>

      <Stack
        width='95%'
        direction='column'
        alignItems='center'
        sx={{backgroundColor: 'white', p: 0.5, mb: 3}}>
        <FormSelect
          fullWidth
          label='Status'
          value={!query?.status ? 'all' : query.status}
          onChange={({target: {value}}) => changeState({
            offset: 0,
            status: value,
          })}
          menus={[
            {value: 'all', view: 'Semua Status'},
            {value: 'request', view: 'Menunggu Approval'},
            {value: 'diterima', view: 'Diterima'},
            {value: 'sedang-diproses', view: 'Sedang diproses'},
            {value: 'siap-dipickup', view: 'Sudah siap'},
            {value: 'sedang-dikirim', view: 'Sedang dikirim'},
            {value: 'selesai', view: 'Selesai'},
            {value: 'batal', view: 'Batal'},
            {value: 'batal-system', view: 'Batal Otomatis'},
          ]}/>
      </Stack>

      <Stack
        width='95%'
        sx={{backgroundColor: 'white', p: 0.5}}
        direction='column'
        alignItems='center'>
        {data.orders?.length > 0
          ? data.orders.map((order, index) => (
            <OrderCard
              refFetch={index === data.orders.length -1 && data.count > (query?.limit || 10) ? lastItemFetchRef : undefined}
              updateOrder={updateOrder}
              order={order}
              index={index}
              key={order._id || index}/>
          ))
          : <Alert severity='warning'>Belum ada Order</Alert>}

        {loading
          ? <LinearProgress sx={{width: '100%'}}/>
          : null}

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
      </Stack>

      {/* <div style={{height: '5vh'}}/> */}
    </div>
  )
}

const OrderCard = ({
  order = {},
  refFetch = undefined,
  index = 0,
  updateOrder = (order_id, payload) => null,
}) => {
  const push = useNavigate();


  return (
    <Card sx={{ width: '100%', mt: index !== 0 ?  1.5  : 0}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {order.pickup_date ? getLocalDate(order.pickup_date, true) : '-'} {order.pickup_time || ''}
        </Typography>
        <Typography variant="h5" component="div" color='green'>
          {getViewableStatus(order?._system?.status)} {order.book_id ? `(${order.book_id})` : null}
        </Typography>
        {!order.book_id
          ? <Typography variant='caption'>Book ID akan muncul setelah pesanan diterima</Typography>
          : null}
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Total Belanja &nbsp; {convertPrice(order?.total_payment || 0, true)}
        </Typography>
        {order.description
          ?  <Typography variant='caption'>Note: {order.description}</Typography>
          : null}
        <Typography variant="body2">
          Terakhir di update: {getLocalDate(order?.updatedAt, true, true)} 
        </Typography>

        <Typography variant='caption' color='red'>
          {order?._system?.status === 'request'
            ? 'Admin akan respon dalam waktu paling lama 15 menit'
            : order?._system?.status === 'diterima'
              ? order?.payment_info?.url
                ? 'Kami akan Review pesanan anda secepatnya'
                : order?._system?.expire_date
                  ? `Harap melakukan pembayaran sebelum ${getLocalDate(order._system.expire_date, true, true)}`
                  : !order?.payment_info?.status
                    ? 'Harap segera melakukan pembayaran'
                    : ''
              : order?._system?.status.includes('batal')
                ? 'Pesanan Batal'
                : order?._system?.status === 'siap-dipickup'
                  ? 'Segera melakukan pengambilan, titik jemput maps *JAJAN ELONA*'
                  : (order?._system?.status === 'selesai' || order?._system?.status === 'on-the-spot')
                    ? 'Pesanan Selesai'
                    : 'Pesanan sedang diproses'}
        </Typography>
      </CardContent>
      <CardActions ref={refFetch}>
        <Button
          variant='outlined'
          onClick={_ => push(`/order/${order?._id}`)}
          size='small'>Lihat Pesanan</Button>
        {order?._system?.status === 'request'
          ? <ButtonCancelOrder
              order_id={order?._id}
              getSpace={false}
              replace={newData => updateOrder(order?._id, {_system: newData?._system})}/>
          : null}
      </CardActions>
    </Card>
  )
}

export default HistoryOrderPage