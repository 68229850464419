import {
  useState,
  useContext,
  useCallback,
} from 'react';
import x from 'axios';
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {gtw} from './apis';
import {handleError, useCancelRequest} from './helpers';
import {AuthContext} from '../utils/auth';

const baseUrl = '/auth';

export const useChangeForgotPassword = _ => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({
      status: '',
      message: '',
    }),
    {token} = useParams(),
    onProcess = async (payload = {password: '', confirm_password: ''}) => {
      setLoading(true);
      setRes({
        status: '',
        message: '',
      });
      try {
        if(!payload.password || !payload.confirm_password)
          throw new Error('data kurang lengkap');
        if(payload.password !== payload.confirm_password)
          throw new Error('ke2 password tidak sama');
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'post',
          url: `${baseUrl}/forgot-password/${token}`,
          data: {
            password: payload.password,
          }
        });
        setRes({
          status: 'ok',
          message: data?.message
        })
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setRes({
          status: 'error',
          message,
        })
      };
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    loading,
    onProcess,
    res,
  }
}

export const usePreRegister = _ => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({
      status: '',
      message: '',
    }),[request, setRequest] = useState(null),
    reset = () => {
      setRes({status: '', message: ''});
      setLoading(false);
    },
    onProcess = async phone_number => {
      setLoading(true);
      setRes({status: '', message: ''});
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'post',
          url: `${baseUrl}/pre-register`,
          data: {phone_number},
          cancelToken: _req.token,
        });

        setRes({status: 'ok', message: data.message || 'tidak ada respon dari server, silahkan coba beberapa saat lagi'})
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setRes({status: 'error', message});
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    loading,
    onProcess,
    reset,
    res,
  }
}

export const useLogin = _ => {
  const [loading, setLoading] = useState(false),
    {login} = useContext(AuthContext),
    [res, setRes] = useState({status: '', message: ''}),
    [request, setRequest] = useState(null),
    forgotPassword = async identity => {
      setLoading(true);
      setRes({status: '', message: ''});
      try {
        if(!identity)
          throw new Error('Masukkan email / no telpon yang terdaftar');
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'patch',
          url: `${baseUrl}/forgot-password`,
          data: {identity},
          cancelToken: _req.token,
        });
        setRes({
          status: 'ok',
          message: data?.message || 'Server sedang sibuk silahkan coba beberapa saat lagi',
        })
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setRes({
          status: 'error',
          message,
        })
      };
      setLoading(false);
    },
    onProcess = (payload) => async event => {
      if(event?.preventDefault)
        event.preventDefault();
      setLoading(true);
      setRes({status: '', message: ''});
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'post',
          url: `${baseUrl}/login`,
          data: payload,
          cancelToken: _req.token
        });
        login(data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setRes({
          status: 'error',
          message,
        })
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {onProcess, res, loading, forgotPassword}
}

export const useActivated = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [res, setRes] = useState({status: '', message: '', token: ''}),
    {login} = useContext(AuthContext),
    {token} = useParams(),
    onProcess = useCallback(async () => {
      setRes({
        status: '',
        message: '',
      });
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'patch',
          url: `${baseUrl}/activated/${token}`,
          cancelToken: _req.token,
        })
        setRes({
          status: 'ok',
          message: 'Akun berhasil diaktifkan, segera ubah profile anda lanjutkan step ini ya',
          token: data,
        })
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setRes({
          status: 'error',
          message,
        })
      };
      setLoading(false);
    }, [token]),
    onLogin = _ => {
      if(!res.token)
        return setRes({status: 'error', message: 'Ada kendala teknis, Harap hubungi admin Jajan Elona'});

      login(res.token);
    };

  useCancelRequest(request);

  return {
    loading,
    res,
    onProcess,
    onLogin,
  }
}

export const useGetAuthPoint = _ => {
  const {headers, getPoint} = useContext(AuthContext),
    [request, setRequest] = useState(null),
    onProcess = useCallback(async () => {
      getPoint(0);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'get',
          url: `${baseUrl}/point`,
          headers,
          cancelToken: _req.token,
        })
        getPoint(data);
      }catch(e) {}
    }, [headers, getPoint]);
  useCancelRequest(request);

  return onProcess;
}

export const useUpdateProfile = _ => {
  const [loading, setLoading] = useState(false),
    {headers, login} = useContext(AuthContext),
    [request, setRequest] = useState(null),
    {pathname} = useLocation(),
    push = useNavigate(),
    onProcess = async function (payload, callback = (error) => null) {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'put',
          url: `${baseUrl}/profile`,
          data: payload,
          cancelToken: _req.token,
          headers,
        });
        if(data) {
          login(data);
          setLoading(false);
          push(pathname)
          return callback();
        }
        callback('server sedang sibuk silahkan coba beberapa saat lagi');
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message);
      }
      setLoading(false);
    };

  useCancelRequest(request);
  return {
    onProcess,
    loading,
  }
}