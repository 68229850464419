import {
  useEffect,
} from 'react';
import {
  Typography,
  Alert,
  Button,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  ArrowRightOutlined,
} from '@mui/icons-material';
import './containers.css';
import {
  useActivated,
  useChangeForgotPassword,
} from '../services/auth';
import {
  useForm,
} from '../helpers/custom';
import {
  ToogleButton,
} from '../components/custom';

const ActivatedPage = () => {
  const {onProcess, loading, onLogin, res} = useActivated();

  useEffect(() => {
    onProcess();
  }, [onProcess])

  return (
    <div className='main-container'>
      <div className="form-container">
        <Typography variant='h3'>Aktifasi Akun</Typography>
        <Divider/>

        <Stack width='100%' sx={{mt: 2}} direction='column' alignItems='center'>
          {loading
            ? <CircularProgress size={35}/>
            : null}

          {res.message
            ? <Alert severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
            : null}

          {res.status === 'ok' && res.token
            ? <Button
                size='large'
                sx={{mt: 3}}
                variant='outlined'
                color='secondary'
                endIcon={<ArrowRightOutlined/>}
                onClick={_ => onLogin()}>Lanjut</Button>
            : null}
        </Stack>
      </div>
    </div>
  )
}

const ForgotPassword = _ => {
  const {data, onChange} = useForm({
    password: '',
    confirm_password: '',
  }),{loading, onProcess, res} = useChangeForgotPassword(),
    push = useNavigate();

  function onSubmit (e) {
    if(e?.preventDefault)
      e.preventDefault();
    onProcess(data);
  }
  return (
    <div className="main-container">
      <div className="form-container">
        <Typography variant='h5'>Ubah Password</Typography>
        <Divider/>


        <Stack width='100%' sx={{mt: 2}} direction='column' alignItems='center'>
          <ToogleButton
            inputLabel='Password Baru'
            name='password'
            value={data.password}
            onChange={onChange}
            sxContainer={{width: '95%', mt: 2, mb: 2}}/>
          <ToogleButton
            inputLabel='Ulangi Password'
            name='confirm_password'
            value={data.confirm_password}
            onChange={onChange}
            sxContainer={{width: '95%', mt: 2, mb: 2}}/>

          <br/>

          {loading
            ? <CircularProgress size={25}/>
            : res.status === 'ok'
              ? <Button onClick={_ => push('/login')} variant='outlined' size='large'>Login</Button>
              : <Button onClick={onSubmit} variant='outlined' size='large'>Simpan</Button>}


          {res.status && res.message
            ? <Alert sx={{mt: 2}} severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
            : null}
        </Stack>
      </div>
    </div>
  )
}

export {
  ActivatedPage,
  ForgotPassword,
};