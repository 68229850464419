import {
  useState,
  useContext,
  useCallback,
} from 'react';
import x from 'axios';
import {
  gtw,
} from './apis';
import {
  useCancelRequest
} from './helpers';
import {
  AuthContext,
} from '../utils/auth';
const baseUrl = '/tax';

export const useSimpleList = _ => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    [data, setData] = useState({
      count: 0,
      taxs: [],
    }),
    onProcess = useCallback(async () => {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'get',
          url: `${baseUrl}?offset=0&limit=100&order_by=createdAt&sort_by=asc&is_active=true`,
          headers,
          cancelToken: _req.token,
        });

        setData(data);
      }catch(e) {}
      setLoading(false);
    }, [headers]);

  useCancelRequest(request);

  return {
    loading,
    data,
    onProcess,
  }
}