import {
  useEffect,
  Fragment,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Alert,
  TextField,
  CardMedia,
  Stack,
} from '@mui/material';
import {
  ArrowLeft,
} from '@mui/icons-material';
import {
  useGetProductId,
} from '../../services/products';
import {
  convertPrice,
  getPromoData,
} from '../../helpers/custom';
import {
  OrderContext,
} from '../../utils/order';
import {
  ModifierItem,
  // validatorQtyOrder,
  StackRowList,
} from '../../components/custom';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';


function FinalProduct () {
  const {onProcess, loading, data} = useGetProductId(),
    [product, setProduct] = useState(null),
    [modifiers, setModifiers] = useState([]),
    {category} = useParams(),
    [description, setDescription] = useState(''),
    push = useNavigate(),
    {data: _order} = useContext(OrderContext),
    isToday = useMemo(() => {
      return _order.pickup_date === new Date().toISOString().split('T')[0];
    }, [_order.pickup_date]),
    [res, setRes] = useState({
      status: '',
      message: '',
    }),
    {addOrder} = useContext(OrderContext),
    modifiersSelected = useMemo(() => {
      let selections = [];
      for(const modifier of modifiers) {
        let modifierOption = modifier.options.filter(el => el.isChecked || el.default);

        if(modifierOption.length > 0) {
          selections.push({
            parent_id: modifier._id,
            name: modifier.identity,
            options: modifierOption.map(option => ({
              option_id: option._id,
              name: option.name,
              price: option.price,
              description: option.description || '',
            }))
          })
        }
      }
      return selections;
    }, [modifiers]),
    [qty, setQty] = useState(1),
    estimatePricee = useMemo(() => {
      if(!product)
        return 0;
      if(modifiersSelected.length === 0)
        return product.price * (qty || 1)
      const modifierAmount = (() => {
        try {
          let count = 0;
          for(const modifier of modifiersSelected) {
            for(const option  of modifier.options)
              count += option.price;
          }

          return count;
        }catch(e) {}
        return 0;
      })()
      if(!qty)
        return product.price + modifierAmount;
      return product.price * qty + (modifierAmount * qty);
    }, [modifiersSelected, qty, product])


  function onChangeModifier (modifier_id, option_id, payload, only_one) {
    setModifiers(prev => prev.map(el => {
      if(el._id === modifier_id) {
        if(only_one)
          return {...el, options: el.options.map(modf => {
            if(modf._id === option_id)
              return {...modf, ...payload};

            return {...modf, isChecked: false, default: false};
          })}
        let tempModifier = {...el},
          getOptionIndex = tempModifier.options?.findIndex(mod => mod._id === option_id);

        if(getOptionIndex !== -1)
          tempModifier.options[getOptionIndex] = {...tempModifier.options[getOptionIndex], ...payload}


        return tempModifier;
      }

      return el;
    }))
  }


  function onInput () {
    setRes({status: '', message: ''});
    let payload = {
      product_id: product.parent_id || data.product_id,
      variation_id: product.variation_id || '',
      is_variation: product.is_variation || false,
      description: product.description || description,
      price: product.price,
      promo: {
        _id: '',
        qty: 0,
        reward: 0,
        name: '',
        _type: '',
        description: '',
        is_active: false,
        is_multiple: true,
        amount: 0,
      },
      product_name: product.is_variation ? `${data.name} - ${product.name}` : data.name,
      modifiers: modifiersSelected,
      qty,
    }


    if(!payload.promo._id && !payload.promo.amount)
      delete payload.promo;

    if(payload.modifiers?.length === 0)
      delete payload.modifiers;

    if(!payload.description)
      delete payload.description;
    if(!payload.variation_id) {
      delete payload.variation_id;
      delete payload.is_variation;
    }

    // const getOrders = getExistsOrder({
    //   product_id: payload.product_id,
    //   is_variation: payload.is_variation,
    //   variation_id: payload.variation_id,
    // });

    // const {status, message} = validatorQtyOrder({
    //   getOrders,
    //   isToday,
    //   productStock: product.stock,
    //   qtyBuy: payload.qty,
    // });

    // if(status === 'error')
    //   return setRes({
    //     status,
    //     message,
    //   })

    if(data.promo) {
      // payload.promo = {...data.promo, amount: 0};
      const advance = !['fix-amount', 'fix-%'].includes(data.promo._type);
      if(advance) {
        payload.advance = true;
        payload.promo = {...data.promo, amount: 0};
      }else {
        const payloadPromo = getPromoData(isToday ? data.promo : {}, {qty: payload.qty, price: payload.price});
        if(payloadPromo)
          payload.promo = payloadPromo;
      }
    }
    addOrder(payload);

    setRes({
      status: 'ok',
      message: `Produk berhasil masuk kekeranjang, silahkan melakukan proses checkout ke halaman order 
*nb: keranjang anda tidak kami simpan, harap tidak muat ulang halaman`
    })

    setDescription('');
    setQty(1);
    if(data && data.is_variation && data.variations.length > 0) {
      let variations = data.variations.sort((a, b) => b.stock - a.stock)
      setProduct({...variations[0], is_variation: true});
    }
    else
      setProduct(data)

    if(data && data.modifiers && data.modifiers.length > 0)
      setModifiers(data.modifiers.map(mod => ({...mod, options: mod.options.map(option => ({...option, isChecked: option.default ? true : false}))})));
  }

  const backDefault = useCallback(async () => {

    if(data && data.is_variation && data.variations.length > 0) {
      let variations = data.variations.sort((a, b) => b.stock - a.stock);
      setProduct({...variations[0], is_variation: true, promo: data?.promo})
    }
    else
      setProduct(data)

    if(data && data.modifiers && data.modifiers.length > 0)
      setModifiers(data.modifiers);
  }, [data])

  function goBack() {
    push(`/${category}`);
  }

  useEffect(() => {
    onProcess();
  }, [onProcess])

  useEffect(() => {
    backDefault()
  }, [backDefault])

  return (
    
  <StackRowList
    alignItems='center'
    justifyContent='flex-start'
    flexDirection='column'>
    
    <StackRowList
      width={{
        md: '85%',
        xs: '95%',
      }}
      justifyContent='center'
      flexDirection='column'
      sx={{
        backgroundColor: '#ffffee',
        p: {
          md: 2,
          xs: 0.5,
        }
      }}>
        
      <Stack
        sx={{mt: 2}}/>

      <StackRowList
        sx={{
          mt: 1,
          mb: 1.5,
        }}>
        <Button
          size='small'
          variant='outlined'
          startIcon={<ArrowLeft/>}
          onClick={goBack}>
          Kembali
        </Button>
      </StackRowList>


      {loading
        ? <CircularProgress size={35}/>
        : data && data.product_id
          ? <Grid display='flex' flexDirection='column' sx={{width: '100%'}}>
              <CardMedia
                title={data.name}
                sx={{
                  height: {
                    xs: 300,
                    md: 500,
                  },
                  mb: 0.8,
                  mt: 0.8,
                  objectFit: 'fill',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
                image={product?.images?.length > 0 ? product.images[0] : '/images/defaultImages.jpeg'}/>
              <Typography variant='body1'>
                {data?.name || '-'}
                {product && product._id
                  ? <Fragment>
                    &nbsp;
                    {product.is_variation && product.name ? `- ${product.name}` : '-'} &nbsp; {convertPrice(product.price || '0')}
                  </Fragment>
                  : null}
              </Typography>
              
              {data?.variations?.length > 0 || modifiers?.length > 0
                ? <Stack
                    width='100%'
                    flexDirection='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    sx={{
                      height: 350,
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}>
                    {data?.variations?.length > 0
                      ? <Fragment>
                          <hr style={{width: '100%'}}/>
                          <Typography variant='h6'>-Variasi-</Typography>
                          <Stack
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            flexWrap='wrap'
                            alignItems='center'
                            sx={{
                              mt: 1,
                              mb: 1.2,
                            }}>
                            {data.variations.map((variant, index) => (
                              // <Button disabled={variant.variation_id === product?.variation_id || (isToday && variant?.stock < 1)} onClick={_ => setProduct({...variant, is_variation: true})} variant='outlined' size='small' key={index} sx={{minWidth: 150, ml: index !== 0 ? 0.5 : 0, mt: 1}}>
                              <Button disabled={variant.variation_id === product?.variation_id} onClick={_ => setProduct({...variant, is_variation: true})} variant='outlined' size='small' key={index} sx={{minWidth: 150, ml: index !== 0 ? 0.5 : 0, mt: 1}}>
                                {variant?.name || '-'} - {variant?.stock || 0}<br/>
                                {convertPrice(variant.price || '0', true)}
                              </Button>
                            ))}
                          </Stack>
                        </Fragment>
                      : null}

                    {modifiers.length > 0
                      ? <Fragment>
                          <hr style={{width: '100%'}}/>

                          <Typography variant='h6'>-Modifier-</Typography>
                          <Stack
                            width='100%'
                            flexDirection='column'
                            alignItems='flex-start'
                            sx={{
                              mt: 1,
                              mb: 1.5,
                            }}>
                            {modifiers.map((modifier, index) => <ModifierItem limit_modifier={product?.limit_option_modifier} onChangeModifier={onChangeModifier} key={index} index={index} modifier={modifier}/>)}
                          </Stack>
                          <hr style={{width: '100%'}}/>
                        </Fragment>
                        
                      : null}

                  </Stack> : null}

              <Typography variant='h6'>Kuantitas - <b>Stock: {product?.stock || 0}</b></Typography>
              <Grid display='flex' sx={{m: 1}}>
                <Button size='small' variant='outlined' onClick={_ => setQty(prev => prev-1)}>-</Button>
                <TextField size='small' placeholder='1' value={qty} type='number' onChange={({target: {value}}) => setQty(value ? parseInt(value, 10) : '')}/>
                <Button size='small' variant='outlined' onClick={_ => setQty(prev => prev+1)}>+</Button>
              </Grid>

              <Typography variant='body1'>Total Harga</Typography>
              <Typography variant='h5' fontWeight='bolder'>{convertPrice(estimatePricee)}</Typography>
              <Typography variant='caption' color='green'>
                {product?.promo?._id && product?.promo?.is_active
                  ? product?.promo?.description || '-'
                  : null}
              </Typography>

              <TextField value={description} onChange={({target: {value}}) => setDescription(value)} label='Catatan' multiline rows={3} sx={{mt: 1.5, mb: 1}}/>


              {res.status && res.message
                ? <Alert sx={{mt: 1, mb: 1.5}} severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
                : null}

              <Grid item display='flex'>
                <Button size='small' variant='outlined' color='warning' onClick={goBack}>Batal</Button>
                <Button size='small' sx={{ml: 1}} variant='outlined' color='info' onClick={onInput}>Input</Button>
              </Grid>

            </Grid>
          : null}



      </StackRowList>
  </StackRowList>
  );
}

export default FinalProduct;