import {
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import {
  Box,
  Badge,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import {
  Restore,
  Person,
  ShoppingBag,
  ShoppingCart,
} from '@mui/icons-material';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {OrderContext} from '../../utils/order';


export const ElonaNavigation = () => {
  const [value, setValue] = useState(2),
    [tabs] = useState(['product', 'order', 'checkout', 'member']),
    push = useNavigate(),
    {data} = useContext(OrderContext),
    {pathname} = useLocation(),
    totalOrder = useMemo(() => {
      if(data.orders.length > 0) {
        let orderQty = 0;
        for(let order of data.orders) {
          orderQty += order.qty;
        }

        return orderQty;
      }
      return 0;
    }, [data]);

  useEffect(() => {
    const getIndex = tabs.findIndex(el => pathname.includes(el));
    setValue(getIndex !== -1 ? getIndex : 0)
  }, [pathname, tabs, setValue]);

  return (
    <Box sx={{pb: 7}}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => setValue(newValue)}>
          <BottomNavigationAction className='product-nav-joyride' label="Produk" icon={<ShoppingBag/>} onClick={_ => push('/all')}/>
          <BottomNavigationAction className='order-nav-joyride' label="History Order" icon={<Restore/>} onClick={_ => push('/order?order_by=createdAt&sort_by=desc&search=')}/>
          <BottomNavigationAction className='cart-nav-joyride' label="Order" icon={<Badge badgeContent={totalOrder} color='primary'><ShoppingCart/></Badge>} onClick={_ => push('/checkout')}/>
          <BottomNavigationAction className='profile-nav-joyride' label="Profile" icon={<Person/>} onClick={_ => push('/member')}/>
        </BottomNavigation>
      </Paper>
    </Box>
  );
}