import {useState, useContext} from 'react';
import x from 'axios';
import {gtw} from './apis';
import {handleError, useCancelRequest} from './helpers';
import {AuthContext} from '../utils/auth';

const baseUrl = '/upload';

export const useUpload = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = async (image, callback = (error, urls) => null) => {
      if(loading)
        return;

      setLoading(true);
      const cancel = await (async function () {
        try {
          let formData = new FormData();
          formData.append('image', image);
          const _req = x.CancelToken.source();
          setRequest(_req);
          const {data} = await gtw({
            url: `${baseUrl}/single`,
            method: 'post',
            data: formData,
            headers,
            cancelToken: _req.token,
          });

          if(data?.url)
            return callback(null, data.url);

          callback('no data', null);
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;
          callback(message, null);
        }
      })();

      if(cancel)
        return;
      setLoading(false);
    }

  useCancelRequest(request);

  return {onProcess, loading};
}