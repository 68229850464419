import {
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import x from 'axios';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {gtw} from './apis';
import {handleError, useCancelRequest} from './helpers';
import {AuthContext} from '../utils/auth';
import {ProductContext} from '../utils/products';
import {convertDataToQuery, getQueryUrl} from '../helpers/custom';

const products = '/products',
  categories = '/categories';


export const useListProducts = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {search} = useLocation(),
    push = useNavigate(),
    {headers} = useContext(AuthContext),
    {category} = useParams(),
    [error, setError] = useState(''),
    [offset, setOffset] = useState(0),
    [data, setData] = useState({count: 0, products: []}),
    query = useMemo(() => {
      if(!search)
        return {search: ''};
      const _query = getQueryUrl(search);
      if(_query.start && _query.start ==='0')
        setOffset(0);
      delete _query.start;
      return _query;
    }, [search]),
    changeState = payload => {
      setOffset(0);
      push(`/${category}${convertDataToQuery({...query, ...payload})}`)
    },
    onProcess = useCallback(async function () {
      setLoading(true);
      setError('');
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          let _query = {
            order_by: 'createdAt',
            sort_by: 'desc',
            ...query,
            category: category !== 'all' ? category : '',
            offset,
            limit: 10,
          }
          const {data: _data} = await gtw({
            method: 'get',
            url: `${products}${convertDataToQuery(_query)}`,
            headers,
            cancelToken: _req.token,
          });
          if(offset === 0)
            return setData(_data);
          setData(prev => ({
            ...prev,
            products: [...prev.products, ..._data.products || []],
          }));
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;

          setError(message);
        }
      })();

      if(cancel)
        return;
      setLoading(false);
      // eslint-disable-next-line
    }, [offset, category, headers, query]),
    hasMore = useMemo(() => {
      if(data && data.count) {
        const countMore = Math.floor(data.count / 10);
        return  parseInt(offset, 10) < countMore;
      }
      return false;
    }, [offset, data])

  useCancelRequest(request);

  return {error, loading, hasMore, category, offset, setOffset, onProcess, data, query, changeState};
}


export const useGetProductId = (default_id) => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({status: '', message: ''}),
    {products: _products, addProduct} = useContext(ProductContext),
    [data, setData] = useState(null),
    {product_id} = useParams(),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = useCallback(async function () {
      setRes({status: '', message: ''});
      setLoading(true);
      const cancel = await (async function () {
        try {
          const id = default_id || product_id,
            check = _products.find(el => el._id === id || el.product_id === id);
          console.log(check)
          if(check && check._id)
            return setData(check);
          const _req = x.CancelToken.source();
          setRequest(_req);
          const {data: _data} = await gtw({
            method: 'get',
            url: `${products}/${id}`,
            headers,
            cancelToken: _req.token,
          })
          addProduct(_data);
          setData(_data);
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;
          setRes({status: 'error', message});
        }
      })()

      if(cancel)
        return;
      setLoading(false)
    }, [product_id, headers, default_id, _products, addProduct])

  useCancelRequest(request);

  return {onProcess, loading, res, data, product_id}
}

// export const useGetProductId = (product_id) => {
//   const [loading, setLoading] = useState(false),
//     [res, setRes] = useState({status: '', message: ''}),
//     [data, setData] = useState(null),
//     [request, setRequest] = useState(null),
//     {headers} = useContext(AuthContext),
//     onProcess = useCallback(async function () {
//       setRes({status: '', message: ''});
//       setLoading(true);
//       const cancel = await (async function () {
//         try {
//           const _req = x.CancelToken.source();
//           setRequest(_req);
//           const {data: _data} = await gtw({
//             method: 'get',
//             url: `${products}/${product_id}`,
//             headers,
//             cancelToken: _req.token,
//           })
//           setData(_data);
//         }catch(e) {
//           const message = handleError(e);
//           if(message.cancel)
//             return true;
//           setRes({status: 'error', message});
//         }
//       })()

//       if(cancel)
//         return;
//       setLoading(false)
//     }, [headers, product_id])

//   useCancelRequest(request);

//   return {onProcess, loading, res, data, product_id}
// }


export const useListCategories = _ => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    [error, setError] = useState(''),
    [data, setData] = useState({categories: [], count: 0}),
    onProcess = useCallback(async function () {
      setLoading(true);
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const {data: _data} = await gtw({
            method: 'get',
            url: `${categories}/0/50`,
            headers,
            cancelToken: _req.token,
          });
          setData(_data);
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;
          setError(message);
        }
      })();
      if(cancel)
        return;
      setLoading(false);
    }, [headers]);

  useCancelRequest(request);

  return {onProcess, loading, error, data}
}