import {
	useState,
	useMemo,
} from 'react';


export const convertDataToQuery = (data) => {
	try {
		const obj = Object.keys(data);
		if(obj.length === 0) return '';
		let query = ``;
		for(let i=0; i<obj.length; i++) {
			if(query.length === 0) {
				query += `?${obj[i]}=${data[obj[i]]}`;
				continue;
			}
			query += `&${obj[i]}=${data[obj[i]]}`;
		}
	
		return query;
	}catch(e) {return ''}
}

export const getQueryUrl = (query) => {
	if(/[&?=]/.test(String(query).toLowerCase())){
		let result = {}
		query.split('?')[1].split('&').forEach(el => result[el.split('=')[0]] = el.split('=')[1]);
		return result;
	}
	return false;
}


export const convertPrice = (num, isPoint = false) => {
	const number_string = num.toString(),
		remainder = number_string.length % 3;
	let money = number_string.substr(0, remainder);
	const thousand = number_string.substr(remainder).match(/\d{3}/g);
	if (thousand) {
		const separator = remainder ? "." : "";
		money += separator + thousand.join(".");
	}
	return isPoint ? money : `Rp. ${money}`;
}


export function useForm (payload) {
  const [data, setData] = useState(payload),
    onChange = ({target = {name: '', value: '', payload: undefined}}) => {
      if(target.payload !== undefined)
        return setData(prev => ({...prev, ...target.payload}));

      setData(prev => ({...prev, [target.name]: target.value}));
    },
    reset = _ => setData(payload);

  return {data, onChange, reset};
}


export function getLocalDate (date, isString = false, isTime = false) {
	const _type = typeof date;
	let _d = new Date(date);
	if(_type === 'number')
		_d = new Date(date * 1000);
	const day = _d.getDay(),
		[year, month, _date] = _d.toISOString().split('T')[0].split('-'),
		alfa = {
			0: 'Minggu',
			1: 'Senin',
			2: 'Selasa',
			3: 'Rabu',
			4: 'Kamis',
			5: 'Jumat',
			6: 'Sabtu',
		},
		time = _d.toLocaleTimeString()

	if(isString)
		return `${alfa[day]}, ${_date}/${month}/${year} ${isTime ? time : ''}`

	return [alfa[day], _date, month, year, _d.toLocaleString().split(', ')[1], time];
}


export const getViewableStatus = (status) => {
  switch (status) {
    case 'request':
      return 'Menunggu Persetujuan';
		case 'diterima':
			return 'Diterima';
    case 'sedang-diproses':
      return 'Sedang diProses';
    case 'siap-dipickup':
      return 'Pesanan sudah siap';
    case 'sedang-dikirim':
      return 'Sedang dikirim';
    case 'selesai':
      return 'Selesai';
		case 'on-the-spot':
			return 'Beli Ditempat';
    case 'batal':
      return 'Batal';
    case 'batal-system':
      return 'Batal oleh Sistem';
    default:
      return 'Unknown';
  }
}

export const getPromoData = (dataPromo, product = {qty: 0, price: 0}) => {
	try {
		const is_multiple = dataPromo.is_multiple !== undefined ? dataPromo.is_multiple : true;
		if(!dataPromo.is_active)
			return undefined;
		const amount = (() => {
			let reward = dataPromo.reward;
			if(dataPromo._type === 'buy-free') {
				const klipatan = dataPromo.qty + reward,
					calculate = Math.floor((product.qty / klipatan)) * reward;
				
				return is_multiple
					? calculate * product.price
					: calculate > 0
						? (reward * product.price)
						: 0;
			}
			if(dataPromo._type === 'buy-fix') {
				const calculate = Math.floor(product.qty / dataPromo.qty);
				return is_multiple // jika berlaku klipatan
					? calculate * reward // min order * total reward
					: calculate > 0 // cek min order
						? reward // jika memenuhi min order baru dapat reward
						: 0;
			}
			if(dataPromo._type === 'fix-%') {
				const _totalPrice = product.price * (product.qty || 1);
				const _percent = Math.floor((_totalPrice * reward) / 100);
				return _percent;
			}
	
			return reward;
		})()
	
	
		return {
			_id: dataPromo._id,
			_type: dataPromo._type,
			qty: dataPromo.qty,
			is_active: dataPromo.is_active || false,
			reward: dataPromo.reward,
			name: dataPromo.name,
			description: dataPromo.description,
			is_multiple,
			amount,
		}
	}catch(e) {}
	return undefined
}


export const useEstimatePrice = ({
	data,
	taxs = [],
	shipping_fee = ''
}) => {
  return useMemo(() => {
    if(data && data.orders && data.orders.length > 0) {
      let total_item_price = 0,
        total_modifier_price = 0,
        total_promo = 0;

      for(let order of data.orders) {
        let countModifier = 0;
        if(order.modifiers && order.modifiers.length > 0)
          for(let modifier of order.modifiers) {
            if(modifier.options.length > 0) {
              for(let option of modifier.options) {
                if(option.price && option.price > 0)
                  countModifier += option.price * (order.qty || 1);
              }
            }
          }
        total_modifier_price += countModifier;
        total_item_price += order.price * (order.qty || 1);
        if(order.promo && order.promo.amount > 0)
          total_promo += order.promo.amount;
      }

      let total_payment = total_item_price + total_modifier_price,
        discount = parseInt(data.discount?.amount || '0', 10);

			let after_pajak = total_payment;
      // @TAX PAJAK PR
			const _taxs = taxs?.map(tax => {
				const amount =  Math.round((parseInt(total_payment, 10) * parseInt(tax.value, 10)) / 100)
				let payload = {
					name: tax?.name,
					_type: tax?._type,
					value: tax?.value,
					tax_id: tax?._id,
					amount,
				};

				if(payload._type === 'fixed')
					payload.amount = payload.value;

				after_pajak += payload.amount;

				

				return payload;
			});

			total_payment = after_pajak
      if(total_promo > 0)
        total_payment = Math.round(total_payment - total_promo);

      if(discount) {
        if(data.discount?._type === '%')
          discount = Math.round((parseInt(total_payment, 10) * parseInt(data.discount?.amount, 10)) / 100);
        let afterDiscount = total_payment - discount;
        total_payment = Math.round(afterDiscount);
      }



			if(shipping_fee)
				total_payment += parseInt(shipping_fee, 10)
      
      return {
        total_item_price,
        total_modifier_price,
        total_payment,
				shipping_fee,
        discount: {
					actual_amount: discount,
				},
        total_promo,
				taxs: _taxs || [],
      }
    }

    return {
      total_item_price: 0,
      total_modifier_price: 0,
      discount: {
				actual_amount: 0,
			},
			shipping_fee: '',
      total_payment: 0,
      total_promo: 0,
			taxs: [],
    }
  }, [data, taxs, shipping_fee]);
}