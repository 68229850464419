import x from 'axios';
import {
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  apiAddress,
  gtw,
} from './apis';
import {
  useForm,
} from '../helpers/custom';
import {
  handleError,
  useCancelRequest,
} from './helpers';
import {AuthContext} from '../utils/auth';

const baseUrl = '/auth/address';

export const useSelectAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {data, onChange, reset} = useForm({
      is_primary: false,
      name: '',
      country: 'indonesia',
      street: '',
      province: {
        id: '',
        value: '',
      },
      city: {
        id: '',
        value: '',
      },
      districts: {
        id: '',
        value: '',
      },
      ward: {
        id: '',
        value: '',
      },
      postal_code: {
        id: '',
        value: '',
      },
      gmap_info: '',
    }),
    [error, setError] = useState(''),
    [provinces, setProvinces] = useState([]),
    [cities, setCities] = useState([]),
    [districts, setDistricts] = useState([]),
    [wards, setWards] = useState([]),
    [postalCode, setPostalCode] = useState([]),
    onGetProvince = useCallback(async () => {
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        
        const {data: _data} = await apiAddress({
          method: 'get',
          url: '/provinsi/get',
          cancelToken: _req.token,
        });
        if(_data.result)
          setProvinces(_data.result.map(el => ({id: el?.id, value: el?.text})))
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, []),
    onGetCities = useCallback(async () => {
      if(!data.province?.id)
        return;
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await apiAddress({
          method: 'get',
          url: `/kabkota/get/?d_provinsi_id=${data.province?.id}`,
          cancelToken: _req.token,
        });
        if(_data.result)
          setCities(_data.result.map(el => ({id: el?.id, value: el?.text})))
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [data?.province?.id]),
    onGetDistricts = useCallback(async () => {
      if(!data?.city?.id)
        return;
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await apiAddress({
          method: 'get',
          url: `/kecamatan/get/?d_kabkota_id=${data.city?.id}`,
          cancelToken: _req.token,
        });
        if(_data.result)
          setDistricts(_data.result.map(el => ({id: el?.id, value: el?.text})));
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [data?.city?.id]),

    onGetWards = useCallback(async () => {
      if(!data?.districts?.id)
        return;
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await apiAddress({
          method: 'get',
          url: `/kelurahan/get/?d_kecamatan_id=${data.districts.id}`,
          cancelToken: _req.token,
        });
        if(_data.result)
          setWards(_data.result.map(el => ({id: el?.id, value: el?.text})));
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [data?.districts?.id]),

    onGetPostalCode = useCallback(async () => {
      if(!data?.districts?.id || !data.city?.id)
        return;
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data: _data} = await apiAddress({
          method: 'get',
          url: `/kodepos/get/?d_kabkota_id=${data.city?.id}&d_kecamatan_id=${data.districts.id}`,
          cancelToken: _req.token,
        });
        if(_data.result)
          setPostalCode(_data.result.map(el => ({id: el?.id, value: el?.text})));
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [data?.districts?.id, data?.city?.id]);

  useCancelRequest(request);

  return {
    loading,
    data,
    provinces,
    cities,
    error,
    districts,
    wards,
    postalCode,
    clear: reset,
    onChange,
    onGetProvince,
    onGetCities,
    onGetDistricts,
    onGetWards,
    onGetPostalCode,
  }
}

export const useListAddress = () => {
  const [loading, setLoading] = useState(false),
    [addresses, setAddresses] = useState([]),
    [request, setRequest] = useState(null),
    [error, setError] = useState(''),
    {headers} = useContext(AuthContext),
    add = (_data) => setAddresses(prev => _data?.is_primary ? [_data, ...prev] : [...prev, _data]),
    remove = address_id => setAddresses(prev => prev.filter(el => el._id !== address_id).map(el => el)),
    onProcess = useCallback(async () => {
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'get',
          url: `${baseUrl}`,
          headers,
          cancelToken: _req.token,
        });
        setAddresses(data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      }
      setLoading(false);
    }, [headers]);

  useCancelRequest(request);
  return {
    loading,
    addresses,
    error,
    onProcess,
    add,
    remove,
  }
}

export const useCreateAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = async function (payload, callback = (error, result) => null) {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          url: `${baseUrl}`,
          method: 'post',
          headers,
          cancelToken: _req.token,
          data: payload,
        });
        callback(null, data);
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message, null);
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    loading,
    onProcess,
  }
}


export const useDeleteAddress = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {headers} = useContext(AuthContext),
    onProcess = async function (address_id, callback = (err) => null) {
      setLoading(true);
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        const {data} = await gtw({
          method: 'delete',
          url: `${baseUrl}/${address_id}`,
          headers,
          cancelToken: _req.token,
        });
        if(data?.message)
          return callback();

        callback('server sedang sibuk silahkan coba beberapa saat lagi');
      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        callback(message);
      }
      setLoading(false);
    };

  useCancelRequest(request);

  return {
    onProcess,
    loading,
  }
}