import {
  TextField,
  Stack,
  Typography,
  Button,
  Divider,
  Alert,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  ExitToApp,
} from '@mui/icons-material';
import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  getQueryUrl,
  useForm,
} from '../../helpers/custom';
import {
  AuthContext,
} from '../../utils/auth';
import {
  useUpdateProfile,
} from '../../services/auth';
import {
  ToogleButton,
  ElonaModal,
  StackRowList,
} from '../../components/custom';
import '../containers.css';

const ChangePassword = ({
  onClose = _ => null,
}) => {
  const {data, onChange} = useForm({
    password: '',
    new_password: '',
  }),
  [res, setRes] = useState({
    status: '',
    message: '',
  }),
  {loading, onProcess} = useUpdateProfile();

  function onSubmit (e) {
    if(res.status && res.message)
      setRes({status: '', message: ''});
    if(e?.preventDefault)
      e.preventDefault();

    onProcess(data, err => {
      let resp = {
        status: 'ok',
        message: 'Password berhasil diubah',
      }
      if(err)
        resp = {
          status: 'error',
          message: err,
        }

      setRes(resp);
    })
  }
  return (
    <ElonaModal open title='Ubah Password' onClose={onClose}>
      <ToogleButton
        name='password'
        value={data.password}
        onChange={onChange}
        fullWidth
        inputLabel='Password Lama'
        sxContainer={{mt: 2, mb: 2}}/>
      <ToogleButton
        inputLabel='Password Baru'
        onChange={onChange}
        fullWidth
        value={data.new_password}
        name='new_password'
        sxContainer={{mt: 2, mb: 2}}/>

      {loading
        ? <CircularProgress size={25}/>
        : <Button size='large' color='primary' onClick={onSubmit}>Simpan</Button>}
      
      {res.message && res.status
        ? <Alert severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
        : null}
    </ElonaModal>
  )
}

const ProfilePage = _ => {
  const {auth, logout} = useContext(AuthContext),
    {loading, onProcess} = useUpdateProfile(),
    {data, onChange} = useForm({
      full_name: auth.full_name || '',
      password: auth.password || '',
      email: auth.email || '',
    }),
    {search} = useLocation(),
    [res, setRes] = useState({status: '', message: ''}),
    [disabled, setDisabled] = useState(true),
    [changePassword, setChangePassword] = useState(false),
    push = useNavigate(),
    [error, setError] = useState({
      full_name: false,
      email: false,
      password: false,
    })

  function onSubmit (e) {
    if(e?.preventDefault)
      e.preventDefault();

    let payload = {
      full_name: data.full_name,
      email: data.email,
    };

    if(data.password)
      payload.password = data.password;

    onProcess(data, error => {
      let response = {
        status: 'ok',
        message: 'Profile berhasil diubah',
      }
      if(error)
        return setRes({
          status: 'error',
          message: error,
        })
      setDisabled(true);
      setRes(response);
    })
  }
  useEffect(() => {
    if(search) {
      const {error: _error = null} = getQueryUrl(search);
      if(_error) {
        const num = _error.split(',');
        setError({
          full_name: num.includes('full_name'),
          email: num.includes('email'),
          password: num.includes('password'),
        });
        setDisabled(false);
      }
    }else
      setError({
        full_name: false,
        email: false,
        password: false,
      });
  }, [search])

  return (
    <Stack
      width='100%'
      height='92vh'
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        overflow: 'auto',
        bgcolor: '#A0E9FF',
        pt: 12
      }}>
      {changePassword
        ? <ChangePassword
            onClose={setChangePassword}/>
        : null}
      <Stack sx={{
        backgroundColor: 'white',
        width: '95%',
        height: '95%',
        overflow: 'auto',
      }} display='flex' flexDirection='column' alignItems='center'>

        <Stack width='100%' flexDirection='row-reverse'>
          <IconButton
            onClick={logout}
            color='error'>
            <ExitToApp fontSize='medium'/>
          </IconButton>
        </Stack>
        <Stack width='100%' sx={{mt: 3, mb: 1}} display='flex' flexDirection='row' alignItems='center' justifyContent='space-around'>
          <Typography variant='body2' color='grey'>
            Informasi Personal
          </Typography>
          <Button
            size='small'
            variant='outlined'
            onClick={_ => setDisabled(prev => !prev)}
            color={disabled ? 'primary' : 'error'}>
            {disabled ? 'Ubah Profile' : 'Batal'}
          </Button>
        </Stack>
        <Divider sx={{width: '100%'}}/>

        <TextField
          size='small'
          sx={{mt: 2, mb: 1, width: '85%'}}
          disabled
          label='No Hp'
          variant='standard'
          value={auth.phone_number || ''}/>
        <TextField
          size='small'
          error={error.full_name}
          sx={{mt: 2, mb: 1, width: '85%'}}
          onChange={onChange}
          name='full_name'
          disabled={disabled}
          variant='standard'
          label='Nama Lengkap'
          value={data.full_name || ''}/>
        <TextField
          size='small'
          error={error.email}
          sx={{mt: 1, mb: 1, width: '85%'}}
          onChange={onChange}
          disabled={disabled}
          variant='standard'
          name='email'
          label='Alamat Email'
          value={data.email || ''}/>
        {auth.password
          ? <Button
              sx={{mt: 1, mb: 1, width: '85%'}}
              onClick={_ => setChangePassword(true)}
              color='secondary'
              size='small'
              variant='outlined'>Ubah Password</Button>
          : <ToogleButton
              sxContainer={{mt: 1, mb: 1, width: '85%'}}
              name='password'
              onChange={onChange}
              value={data.password}
              inputLabel='Password'/>}
        

        {!disabled
          ? <Stack width='95%' sx={{mt: 2}} flexDirection='column' alignItems='center'>
              {loading
                ? <CircularProgress size={25}/>
                : <Button
                    onClick={onSubmit}
                    size='small'
                    variant='outlined'>Simpan</Button>}
              {res.status && res.message
                ? <Alert sx={{mt: 2}} severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
                : null}
            </Stack>
          : null}

        <StackRowList justifyContent='space-around'>
          <Button
            size='small'
            onClick={_ => push('/member/address')}
            variant='outlined'
            color='primary'>List Alamat</Button>
          <Button
            size='small'
            onClick={_ => push(`/member/histories`)}
            variant='outlined'
            color='secondary'>Lacak Point</Button>
        </StackRowList>
          
      </Stack>
    </Stack>
  )
}

export default ProfilePage;