import {createContext, useReducer, useCallback} from 'react';
import {jwtDecode} from 'jwt-decode';

let initialState = {
	headers: {
		Authorization: '',
	},
	auth: {
    member_id: '',
    email: '',
    full_name: '',
		phone_number: '',
		password: '',
    class: '',
    current_point: 0,
    is_active: false,
    createdAt: '',
  },
}

if(localStorage.getItem('token')) {
	try {
		const currentToken =  localStorage.getItem('token');
		if(currentToken !== undefined && currentToken) {
			const decode = jwtDecode(currentToken);
			if(decode.exp && (decode.exp * 1000) < Date.now())
        localStorage.removeItem('token');
      else {
        initialState = {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
          auth: {...initialState.auth, ...decode, member_id: decode._id},
        }
      }
		}
	}catch(e) {console.error(e, "ERROR CONVERT TOKEN")}
}


const AuthContext = createContext({
	headers: {
		Authorization: '',
	},
	auth: {
    member_id: '',
    email: '',
    full_name: '',
		password: '',
		phone_number: '',
    class: '',
    current_point: 0,
    is_active: false,
    createdAt: '',
  },
	login: (token) => {},
	getPoint: _point => {},
	logout: () => {},
})

function authReducer (state, action) {
	switch(action.type) {
		case 'LOGIN':
			return {...state, ...action.payload};
		case 'LOGOUT':
			return {
				...state,
        headers: {
          Authorization: '',
        },
        auth: initialState.auth,
			}
		case 'AUTH_POINT':
			return {
				...state,
				auth: {
					...state.auth,
					...action.payload,
				}
			}
		default:
			return state;
	}
}

function AuthProvider (props) {
	const [state, dispatch] = useReducer(authReducer, {...initialState, getPoint: _point => null, signin: () => null, signout: () => null, updateCart: () => null, updatePhone: () => null}),
		login = (token) => {
			const decode = jwtDecode(token);
			if(decode.member_id) {
        localStorage.setItem('token', token);
        dispatch({
					type: 'LOGIN',
					payload: {
						auth: decode,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				})
				return;
			}
			dispatch({type: 'LOGOUT', payload: null});
		},
		getPoint = useCallback((result = {current_point: 0, class: 'silver'}) => {
			dispatch({
				type: 'AUTH_POINT',
				payload: result,
			})
		}, [dispatch]),
		logout = () => {
			localStorage.removeItem('token');
			dispatch({type: 'LOGOUT', payload: null});
		};

	return (
		<AuthContext.Provider
			value={{...state, login, logout, getPoint}}
			{...props}/>
	)
}

export {AuthProvider, AuthContext};