import {
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import x from 'axios';
import {
  useLocation,
} from 'react-router-dom';
import {gtw} from './apis';
import {handleError, useCancelRequest} from './helpers';
import {AuthContext} from '../utils/auth';
import {convertDataToQuery, getQueryUrl} from '../helpers/custom';

const baseUrl = '/history';

export const useList = _ => {
  const [loading, setLoading] = useState(false),
    {headers} = useContext(AuthContext),
    [request, setRequest] = useState(null),
    {search} = useLocation(),
    [error, setError] = useState(''),
    [data, setData] = useState({
      count: 0,
      histories: [],
    }),
    [query, setQuery] = useState({
      offset: 0,
      limit: 10,
      order_by: 'createdAt',
      sort_by: 'desc',
    }),
    changeState = (payload) => setQuery({...query, ...payload}),
    onProcess = useCallback(async () => {
      setLoading(true);
      setError('');
      try {
        const _req = x.CancelToken.source();
        setRequest(_req);
        let _query = {...query};
        if(search) {
          let _search = getQueryUrl(search);
          _query = {
            ...query,
            ..._search,
          }
        }
        const {data: _data} = await gtw({
          method: 'get',
          url: `${baseUrl}${convertDataToQuery(_query)}`,
          headers,
          cancelToken: _req.token,
        });
        if(query.offset === 0) {
          setLoading(false);
          return setData(_data);
        }
        setData(prev => ({
          ...prev,
          histories: [...prev.histories, ..._data.histories],
        }))

      }catch(e) {
        const message = handleError(e);
        if(message.cancel)
          return;
        setError(message);
      };
      setLoading(false);
    }, [headers, query, search]),
    hasMore = useMemo(() => {
      if(data.count) {
        const countMore = Math.floor(data.count / query.limit);
        return query.offset < countMore;
      }
      return false;
    }, [query.offset, query.limit, data.count]);

  useCancelRequest(request);

  return {
    loading,
    error,
    hasMore,
    query,
    data,
    changeState,
    onProcess,
  }
}