import {useContext} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import {AuthContext, AuthProvider} from './utils/auth';
import {OrderProvider} from './utils/order';
import {ProductProvider} from './utils/products';
import {
  LoginPage,
} from './containers/login-page';
import {
  ActivatedPage,
  ForgotPassword,
} from './containers/activated';
// import HomePage from './containers/home';
import HomePage from './containers/home/main';
import ProductDetailPage from './containers/home/detail';


import ProfilePage from './containers/profile/main';
import HistoryPointPage from './containers/profile/history';
import AddressPage from './containers/profile/address';
import LayoutComponent from './components/layout';

import OrderPage from './containers/order/main';
import HistoryOrderPage from './containers/order/history';
import DetailOrderPage from './containers/order/detail';


const PublicRoute = _ => {
  const {headers, auth} = useContext(AuthContext),
    {state} = useLocation();

  if(auth && auth.member_id && headers.Authorization)
    return <Navigate to={state?.url || '/all'}/>

  return <Outlet/>
}

const PrivateRoute = _ => {
  const {headers, auth} = useContext(AuthContext),
    {search, pathname} = useLocation();


  if(auth && auth.member_id && headers.Authorization) {
    if(!auth.email || !auth.password || !auth.full_name) {
      let errors = [];
      if(!auth.email)
        errors.push('email');
      if(!auth.password)
        errors.push('password');
      if(!auth.full_name)
        errors.push('full_name');

      if(!pathname.includes('member'))
        return <Navigate to={`/member?error=${errors.join(',')}`}/>
    }
    if(pathname === '/')
      return <Navigate to='/all'/>
  
    return (
      <OrderProvider>
        <ProductProvider>
          <LayoutComponent>
            <Outlet/>
          </LayoutComponent>
        </ProductProvider>
      </OrderProvider>
    )
  }

  return <Navigate state={{url: `${pathname}${search}`}} to='/login'/>
}

const App = _ => (
  <Router>
    <AuthProvider>
      <Routes>

        {/* Private Router */}
        <Route
          path='/'
          element={<PrivateRoute/>}>
          <Route path='member' element={<Outlet/>}>
            <Route path='' element={<ProfilePage/>}/>
            <Route path='histories' element={<HistoryPointPage/>}/>
            <Route
              path='address'
              element={<AddressPage/>}/>
          </Route>
          <Route path='order' element={<Outlet/>}>
            <Route path='' element={<HistoryOrderPage/>}/>
            <Route path=':order_id' element={<DetailOrderPage/>}/>
          </Route>
          <Route path='checkout' element={<OrderPage/>}/>
          <Route path=':category' element={<Outlet/>}>
            <Route path='' element={<HomePage/>}/>
            <Route path=':product_id' element={<ProductDetailPage/>}/>
          </Route>
        </Route>

        {/* Public Router */}
        <Route
          path='/login'
          element={<PublicRoute/>}>
          <Route path='' element={<LoginPage/>}/>
          <Route path='activated/:token' element={<ActivatedPage/>}/>
          <Route path='forgot-password/:token' element={<ForgotPassword/>}/>
        </Route>
      </Routes>
    </AuthProvider>
  </Router>
)

export default App