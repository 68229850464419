import {
  useEffect,
  useState,
} from 'react';
import {
  Stack,
  Button,
  LinearProgress,
  Alert,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Add,
  ArrowLeft,
} from '@mui/icons-material';
import {
  useListAddress,
} from '../../services/address';
import {
  StackRowList,
} from '../../components/custom';
import {
  CreateAddress,
  HistoryCard,
} from '../../components/address';

const AddressPage = _ => {
  const {addresses, loading, onProcess, remove, add, error} = useListAddress(),
    [create, setCreate] = useState(false),
    push = useNavigate();

  useEffect(() => {
    onProcess();
  }, [onProcess])


  return (
    <Stack
      width='100%'
      height='92vh'
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        overflow: 'auto',
        bgcolor: '#A0E9FF',
        pt: 12
      }}>
      {create
        ? <CreateAddress onClose={setCreate} add={add}/>
        : null}
      <Stack width='95%' sx={{backgroundColor: 'white', p: 0.5, mb: 2}} direction='row' justifyContent='space-between' alignItems='center'>
        <StackRowList justifyContent='space-between'>
          <Button
            size='small'
            variant='outlined'
            color='info'
            startIcon={<ArrowLeft/>}
            onClick={_ => push(`/member`)}>
            Kembali
          </Button>
          <Button
            onClick={_ => setCreate(true)}
            size='small'
            variant='outlined'
            color='primary'
            endIcon={<Add/>}>Tambah Alamat</Button>

        </StackRowList>
      </Stack>

      <Stack
        width='95%'
        sx={{backgroundColor: 'white', p: 0.5}}
        direction='column'
        alignItems='center'>
        {addresses.length > 0
          ? addresses.map((address, index) => (
            <HistoryCard remove={remove} address={address} index={index} key={address._id || index}/>
          ))
          : <Alert severity='warning'>Belum ada Alamat</Alert>}

        {loading
          ? <LinearProgress sx={{width: '100%'}}/>
          : null}

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
      </Stack>
    </Stack>
  )
}

export default AddressPage