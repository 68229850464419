import {
  useEffect,
  useMemo,
  Fragment,
  useState,
  useContext,
} from 'react';
import {
  Stack,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Alert,
  Grid,
  ListItem,
  TextareaAutosize,
  TextField,
  ListItemText,
  IconButton,
} from '@mui/material';
import {
  DeleteOutlined,
  RemoveOutlined,
  AddOutlined,
  Cancel,
} from '@mui/icons-material';
import {
  FormSelect,
  ElonaModal,
  StackRowList,
} from '../../components/custom';
import {
  HistoryCard
} from '../../components/address';
import {
  convertPrice,
} from '../../helpers/custom';
import {
  useSimpleList,
} from '../../services/tax';
import {
  OrderContext,
} from '../../utils/order';
import {
  AuthContext,
} from '../../utils/auth';
import {
  useEstimatePrice,
} from '../../helpers/custom';
import {
  useCreateOrder,
} from '../../services/order';
import {
  useListAddress,
} from '../../services/address';
import {
  decrypt,
} from '../../helpers/crypto';
import {
  useNavigate,
} from 'react-router-dom';

function CheckoutPage () {
  const {data, onChange, updateOrders, clearState} = useContext(OrderContext),
    [res, setRes] = useState({status: '', message: ''}),
    [term, setTerm] = useState(false),
    {loading: _loadTax, onProcess: _processTax, data: _tax} = useSimpleList(),
    {loading: _loadOrder, onProcess: _processOrder} = useCreateOrder(),
    [minimize, setMinimize] = useState(false),
    {auth} = useContext(AuthContext),
    totalQty = useMemo(() => {
      let item = 0;
      for(let order of data.orders)
        item+=order.qty;
      return item;
    }, [data]);


  const estimate = useEstimatePrice({
    data,
    taxs: _tax.taxs,
  })

  function onSubmitOrder () {
    setRes({status: '', message: ''});
    let payloadData = {...data, ...estimate, discount: {...data.discount, ...estimate.discount}, pos: true};
    payloadData.shipper = {
      ...data.shipper,
      member_id: auth.member_id,
      name: auth.full_name,
      phone_number: auth.phone_number,
    }
    for(let order of payloadData.orders) {
      let amount_modifier_price = 0,
        price_order = order.price * (order.qty || 1);
    
      if(order?.modifiers?.length > 0) {
        for(let modifier of order.modifiers) {
          for(let option of modifier.options) {
            amount_modifier_price += (option.price * (order.qty || 1));
          }
        }
      }

      const total_price = price_order + amount_modifier_price;
      order.total_price = total_price;
    }
    if(payloadData.shipper?.services.includes('kurir') && !payloadData.receiver?.address)
      return setRes({status: 'error', message: 'Pilih / Buat Alamat untuk pengiriman via Elona'})
    _processOrder(payloadData, (error, _result) => {
      if(error)
        return setRes({status: 'error', message: error});
      
      if(_result) {
        clearState();
        setRes({status: 'success', message: 'Pesanan kamu sedang dalam tinjauan, admin akan segera respon secepatnya ya'});
      }
    })
  }

  useEffect(() => {
    _processTax();
  }, [_processTax])


  return (
    <Stack sx={{backgroundColor: 'white', overflowY: 'auto', p: 0.5}} width='95%' height='auto'>
      <Typography variant='h5'>Checkout</Typography>
      {term
        ? <ElonaModal
            title='Syarat & Ketentuan'
            open
            boxStyle={{
              maxHeight: '85%',
              overflow: 'auto'
            }}
            onClose={setTerm}>
            <Typography variant='h6'>
              Demi Kenyamanan Belanja Online di sistem Jajan Elona, maka ada beberapa ketentuan sebagai berikut:<br/>
            </Typography>
            <Typography variant='subtitle1'>
              1. Stock dapat berubah sewaktu-waktu<br/>
              2. Admin akan segera menghubungi Pembeli jika ada item yang habis/ diubah<br/>
              3. Pesanan yang di input, tidak bisa diubah lagi harap di periksa lagi sebelum melakukan request Order (Jika ada perubahan dan status masih request segera hub admin kita)<br/>
              4. Jika pesanan sudah kami terima, Pembayaran maksimal 20 menit setelah pesanan dibuat<br/>
              5. Jika tidak melakukan pembayaran setelah waktu yang ditentukan, maka pesanan akan otomatis di batalkan<br/>
              6. Jika pesanan sampai dibatalkan karena tidak melakukan pembayaran dr waktu yang ditentukan, maka Sistem akan memBlokir Akun Member<br/>
              7. Customer dengan nomor yang terblokir tetap bisa berbelanja secara On Site ke store Jajan Elona<br/>
              8. Data Customer untuk keperluan internal, tidak akan disebarluaskan<br/>
            </Typography>

            <Typography variant='h6'>
              Terima kasih atas kerjasamanya<br/>

              Salam Hangat,<br/><br/>

              Jajan Elona<br/>
            </Typography>

            <Button size='small' variant='outlined' onClick={_ => setTerm(false)}>
              Tutup
            </Button>
          </ElonaModal>
        : null}

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <TextField
          label='Tanggal Ambil'
          type='date'
          size='small'
          inputProps={{
            min: new Date().toISOString().split('T')[0],
          }}
          sx={{width: '45%'}}
          variant='standard'
          name='pickup_date'
          value={data.pickup_date}
          onChange={({target}) => {
            let d = new Date();
            d.setHours(0,0,0,0);
            let b = new Date(target.value);

            if(Math.floor(+b / 1000) < Math.floor(+d / 1000))
              return;
            clearState();
            
            onChange({
              payload: {
                pickup_date: target.value,
                shipper: {
                  ...data.shipper,
                  services: 'self_services',
                },
              }
            })
          }}/>
        <FormSelect
          label='Metode Pengambilan'
          sx={{width: '48%'}}
          onChange={({target: {value}}) => {
            let payload = {
              shipper: {
                ...data.shipper,
                services: value,
              }
            }
            if(value === 'self_services')
              payload.receiver = {
                ...data.receiver,
                name: auth.full_name || auth.username || data.shipper.name,
                phone_number: auth.phone_number || data.shipper.phone_number,
              }
            if(value === 'kurir')
              setMinimize(true);
            else
              payload.receiver = {
                ...data.receiver,
                name: '',
                phone_number: '',
                address: '',
                address_id: '',
                districts: '',
                gmap_info: '',
              }
            onChange({
              payload,
            })
          }}
          value={data.shipper?.services}
          menus={[
            {value: 'self_services', view: 'Ambil Sendiri'},
            {value: 'others', view: 'Gosend/Grab/Maxim/etc'},
            {value: 'kurir', view: 'Pengiriman via Elona'}, 
          ]}/>
      </Stack>

      {data?.receiver?.address
        ? <Typography variant='body1' fontWeight='bolder'>
            {data?.receiver?.name || ''} - {data?.receiver?.phone_number}<br/>
            {data?.receiver?.address || ''}
          </Typography>
        : null}
      {data?.shipper?.services === 'kurir'
        ? minimize
          ? <SelectAddress
            setMinimize={setMinimize}
            onChange={x => {
              onChange(x);
              setMinimize(false);
            }}
            receiver={data?.receiver}/>
          : <Button
              size='small'
              fullWidth
              variant='outlined'
              sx={{mt: 1, mb: 1}}
              onClick={_ => setMinimize(true)}>Lihat Alamat Saya</Button>
        : null}

      
      <div className="order-item-container">
        {data.orders.length > 0
          ? data.orders.map((order, index) => <OrderItem updateOrders={updateOrders} orderLength={data.orders.length} key={index} _index={index} order={order}/>)
          : <Typography component='span' variant='body1'>Belum ada produk yang dipilih</Typography>}

      </div>
      <Divider/>
      <div className="order-bottom-container">

        <div className="order-bottom-container__footer">
          <Typography variant='body1' fontWeight='bolder'>{data.payment_info?.method === 'trf' ? 'Transfer' : data.payment_info.method}</Typography>
          <Typography component='span'>Total Produk ({totalQty}): {convertPrice(estimate.total_item_price + estimate.total_modifier_price, true)}</Typography><br/>

          {_loadTax
            ? <CircularProgress size={15}/>
            : null}
            
          {estimate.taxs?.length > 0
            ? estimate.taxs.map(tax =>
              <Fragment key={tax.tax_id}>
                <Typography component='span'>{tax?.name} {tax?._type === '%' ? `${tax?.value}%` : ''}: {convertPrice(tax?.amount || 0, true)}</Typography>
                <br/>
              </Fragment>)
            : null}

          <Typography component='span' color='red'>Promo: {convertPrice(estimate.total_promo|| '0', true)}</Typography><br/>
        </div>
        <Typography variant='h3'><b>{convertPrice(estimate.total_payment)}</b></Typography><br/>

      </div>
      <TextareaAutosize
        maxRows={4}
        minRows={3}
        placeholder='jika mau request jam bisa disini ya'
        value={data.description}
        style={{fontSize: 17}}
        onChange={({target: {value}}) => onChange({name: 'description', value})}/>

      {res.status && res.message
        ? <Alert sx={{mt: 1}} severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
        : null}

      <Typography variant='caption' color='red' sx={{mt: 2}}>
        dengan klik tombol Request Order,<br/>maka Anda menyetujui &nbsp;<Button size='small' variant='text' onClick={_ => setTerm(true)} sx={{textTransform: 'none', m: 0, p: 0}}>S&K yang berlaku</Button>
      </Typography>

      <Grid sx={{mt: 1, mb: 1}} display='flex' justifyContent='flex-end' alignItems='center'>
        {_loadOrder
          ? <CircularProgress size={25}/>
          : <Fragment>
              <Button variant='outlined' color='error' size='small' sx={{mr: 1}}>Batal</Button>
              <Button disabled={!data.orders.length > 0} variant='contained' color='success' size='small' onClick={onSubmitOrder}>Request Order</Button>
            </Fragment>}

      </Grid>
    </Stack>
  )
}

const SelectAddress = ({
  receiver = {},
  setMinimize = _ => null,
  onChange = ({name, value, payload}) => null,
}) => {
  const {loading, onProcess, addresses, error} = useListAddress(),
    push = useNavigate();

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <Fragment>
      {receiver?.address_id
        ? <StackRowList justifyContent='flex-end'>
            <IconButton
              size='small'
              onClick={_ => setMinimize(false)}
              ><Cancel/></IconButton>
          </StackRowList>
        : null}
      <StackRowList display='flex' direction='row' flexWrap='wrap' justifyContent='flex-start' sx={{width: '100%', height:250, overflow: 'auto', mt: 1, mb: 1}}>
        
        {loading
          ? <CircularProgress size={30}/>
          : null}
        {addresses?.length > 0
          ? addresses.map((address, index) => (
            <HistoryCard key={index}
              address={address}
              viewOnly
              index={index}
              onChoose={(_address) => onChange({
                payload: {
                  receiver: {
                    ...receiver,
                    address_id: _address._id,
                    name: _address?.receiver?.name,
                    phone_number: _address?.receiver?.phone_number,
                    districts: _address?.districts || '',
                    address: `${_address?.name || ''} - ${decrypt(_address?.street) || ''}, ${_address?.districts || ''} ${address?.wards || ''} - ${_address?.city || ''} ${_address?.province || ''}, ${_address?.postal_code || ''} ${_address?.gmap_info || ''}`,
                  }
                }
              })}
              disabled={receiver?.address_id === address?._id}/>
          ))
          : <Button size='small' onClick={_ => push('/member/address')}>Buat Alamat Baru</Button>}

        {error
          ? <Alert severity='error'>{error}</Alert>
          : null}
        
      </StackRowList>
    </Fragment>
  )
}

const OrderItem = ({
  _index,
  order,
  orderLength,
  updateOrders = _ => null,
}) => {
  const [isEdit, setIsEdit] = useState(false),
    [tempValues, setTempValues] = useState('');

  useEffect(() => {
    setTempValues(order.qty || '');
  }, [order?.qty])

  function onAddMinQty (query = '+') {
    let qty = order.qty;
    if(query === '+')
      qty++
    else if(qty > 1)
      qty--


    updateOrders({
      type: 'update',
      id: order.variation_id || order.product_id,
      key: _index,
      payload: {qty}
    })
  }

  const total_price = useMemo(() => {
    let totalPrice = (order.price || 0) * (order.qty || 1);
    if(order?.modifiers?.length > 0)
      for(const mod of order.modifiers)
        for(const option of mod.options) {
          if(option.price > 0)
            totalPrice += (option.price || 0) * (order.qty || 1);
        }

    return totalPrice;
  }, [order.qty, order.price, order.modifiers])

  return (
    <ListItem sx={{borderBottomColor: 'grey', borderBottom: orderLength === _index + 1 ? 0 : 1, borderBottomStyle: {opacity: 0.5}}}>
      <ListItemText
        primary={
          <span className='top-item'>
            <Typography variant='subtitle1'>{_index+1 +'.' + order.product_name || '-'}</Typography>

            <IconButton
              onClick={_ => updateOrders({
                type: 'delete',
                id: order.product_id,
                key: _index,
              })} size='small' color='error'><DeleteOutlined/></IconButton>
          </span>
        }
        secondaryTypographyProps={{display: 'flex', flexDirection: 'column'}}
        secondary={
          <span className='secondary-item'>
            {order?.modifiers?.length > 0
              ? order.modifiers.map((modifier, index) => <Fragment key={index}>
                <Typography component='span' variant='subtitle1'>{modifier.name || '-'}</Typography>
                <Typography component='span' variant='caption'>
                  {modifier.options?.map((option, __index) => (
                    <Fragment key={__index}>
                      - {option.name || '-'} &nbsp; {option.price ? convertPrice(option.price || '0') : ''} {order.qty > 1 && option.price ? <>
                        &nbsp; x {order.qty}
                      </> : null}
                      {option.description
                        ? <><br/>&nbsp; - &nbsp;<b style={{color: 'green'}}>"{option.description}"</b></>
                        : null}
                      <br/>
                    </Fragment>
                  ))}
                </Typography>
              </Fragment>)
              : null}
            {order?.description
              ? <Typography component='span' variant='caption'>{order.description}</Typography>
              : null}

            <Stack component='span' direction='row' justifyContent={isEdit ? 'space-between' : 'flex-end'} alignItems='center'>
              <Typography component='span' variant='subtitle2' sx={{textAlign: 'right', width: '100%'}}>
                @{convertPrice(order.price)}/Item &nbsp; - &nbsp;
              </Typography>
              {isEdit
                ? <Fragment>
                    <Stack sx={{zIndex: 5}} component='span' direction='row' justifyContent='space-around' alignItems='center' width='80%'>
                      <IconButton LinkComponent='span' size='small' onClick={_ => onAddMinQty('-')}>
                        <RemoveOutlined fontSize='15' color='error'/>
                      </IconButton>
                      <input
                        style={{width: 50, height: 25}}
                        type='text'
                        value={tempValues}
                        onChange={({target: {value}}) => setTempValues(value)}
                        size='small'/>
                      <IconButton LinkComponent='span' size='small' onClick={_ => onAddMinQty('+')}>
                        <AddOutlined color='primary' fontSize='15'/>
                      </IconButton>
                    </Stack>
                    <span className='shadow' onClick={_ => {
                      if(tempValues)
                        updateOrders({
                          type: 'update',
                          id: order.variation_id || order.product_id,
                          key: _index,
                          payload: {qty: parseInt(tempValues || '1', 10)}
                        })
                      setIsEdit(false);
                    }}/>
                  </Fragment>
                : <Button onClick={_ => setIsEdit(true)} size='small' variant='text' color='secondary'>
                    {order.qty || 1} Item
                  </Button>}
            </Stack>
            
            <Typography component='span' variant='caption' sx={{textAlign: 'right', width: '100%'}}>{convertPrice(total_price || 0)}</Typography>
            {order.promo && order.promo.is_active
              ? <Stack direction='column' component='span'>
                  <Typography component='span'
                    variant='caption'
                    sx={{textAlign: 'right', width: '100%'}}
                    color='red'>
                    {order.promo?.name || '-'} - {convertPrice(order.promo?.amount, true)}
                  </Typography>
                  
                  {order.promo?.is_multiple
                    ? null
                    : <Typography component='span'
                        variant='caption'
                        sx={{textAlign: 'right', width: '100%'}}
                        color='red'>
                        Tidak Berlaku kelipatan
                      </Typography>}
                  
                </Stack> : null}
          </span>
        }/>
    </ListItem>
  )
}

export default CheckoutPage;