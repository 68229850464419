import {
  useState,
} from 'react';
import {
  TextField,
  Button,
  Typography,
  LinearProgress,
  Alert,
  Stack,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  Logo,
  ToogleButton,
  ElonaModal,
  StackRowList,
} from '../components/custom';
import {
  useLogin,
  usePreRegister,
} from '../services/auth';
import {
  useForm,
} from '../helpers/custom';
import './containers.css';

const LoginPage = () => {
  const {data, onChange} = useForm({
    identity: '',
    showPassword: false,
    password: '',
  }),
    {loading, onProcess, forgotPassword, res} = useLogin(),
    [preRegister, setPreRegister] = useState(false),
    [phoneNumber, setPhoneNumber] = useState(''),
    {loading: _loadPreRegister, onProcess: _processPreRegister, res: _resPreRegister, reset} = usePreRegister();


  function onCloseModal () {
    setPhoneNumber('');
    setPreRegister(false);
    reset();
  }
  return (
    <div className='main-container'>
      {preRegister
        ? <ElonaModal
            open
            onClose={onCloseModal}
            boxStyle={{
              overflow: 'auto',
              height: '35%',
            }}>
            <Typography
              variant='body1'
              sx={{mb: 1}}
              fontWeight='bolder'>
              Silahkan masukkan No Whatsapp anda
            </Typography>
            <Typography
              variant='caption'
              color='green'
              sx={{mb: 1}}
              fontWeight='bolder'>
              *nb: masih Beta, maaf harap menunggu pengiriman Whatsapp 1x24jam / bisa kirim ke email support@jajanelona.com
            </Typography>

            <TextField
              fullWidth
              variant='outlined'
              label='Nomor Whatsapp'
              sx={{
                mt: 1,
                mb: 2,
              }}
              placeholder='Eg 08xxxxxxxx'
              type='number'
              size='small'
              value={phoneNumber}
              onChange={({target: {value}}) => setPhoneNumber(value)}/>

            {_loadPreRegister
              ? <CircularProgress size={35}/>
              : <StackRowList
                  justifyContent='space-around'>
                  <Button
                    size='small'
                    variant='outlined'
                    color='error'
                    onClick={onCloseModal}>
                    Batal
                  </Button>
                  <Button
                    size='small'
                    variant='outlined'
                    color='success'
                    onClick={_ => _processPreRegister(phoneNumber)}>
                    Register
                  </Button>
                </StackRowList>}

            {_resPreRegister.status && _resPreRegister.message
              ? <Alert
                  sx={{mt: 2}}
                  severity={_resPreRegister.status === 'ok' ? 'success' : _resPreRegister.status}>
                  {_resPreRegister.message}
                </Alert>
              : null}
          </ElonaModal>
        : null}
      <form className='form-container' onSubmit={onProcess(data)}>
        <Logo noFlex/>
        <br/>
        <br/>
        <br/>
        <Divider/>
        {/* <h1>Member Elona</h1> */}
        <TextField
          sx={{mt: 1, mb: 1}}
          size='small'
          name='identity'
          value={data.identity}
          onChange={onChange}
          variant='standard'
          label='Email / No Wa'/>

        <ToogleButton
          sxContainer={{mt: 1, mb: 1}}
          inputLabel='Password'
          name='password'
          onChange={onChange}
          value={data.password}/>

        <Stack
          sx={{mt: 1, mb: 1}}
          direction='row'
          alignItems='center'
          justifyContent='space-between'>

          <Typography color='blue' onClick={_ => setPreRegister(true)} variant='caption'>Pre Registrasi</Typography>

          <Typography color='blue' onClick={_ => forgotPassword(data.identity)} variant='caption'>Lupa password?</Typography>

        </Stack>

        {loading
          ? <LinearProgress sx={{m:2}}/>
          : <Button type='submit' sx={{m: 1}} color='secondary'>Login</Button>}

        {res.status && res.message
          ? <Alert severity={res.status === 'ok' ? 'success' : 'error'}>{res.message}</Alert> : null}

        <Divider/>
      </form>
    </div>
  )
};

export {
  LoginPage,
}