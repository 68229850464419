import {
  useState,
  useEffect,
} from 'react';
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
} from 'react-joyride';

export const ElonaJoyride = ({
  name = '',
  steps = [],
  run = true,
  setRun = _ => null,
}) => {
  const [stepIndex, setStepIndex] = useState(0),
    handleJoyrideCallback = (data) => {
      const { action, index, status, type } = data;

      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        // Update state to advance the tour
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        let d = new Date();
        d.setDate(d.getDate() + 90);
        localStorage.setItem(name, JSON.stringify({
          value: true,
          exp_in: Math.floor(+d / 1000),
        }))
        setRun(false);

      }
    };

  useEffect(() => {
    const getLocalStorage = localStorage.getItem(name)
    try {
      if(getLocalStorage) {
        const _local = JSON.parse(getLocalStorage);
        if(_local?.value) {
          if(_local.exp_in < +new Date() / 1000) {
            localStorage.removeItem(name);
          }else
            setRun(false);
        }
      }
    }catch(e) {}
  }, [name, setRun])

  console.log('kesini ?')

  return (
    <Joyride
      showProgress
      run={run}
      stepIndex={stepIndex}
      continuous
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
      callback={handleJoyrideCallback}
      steps={steps}/>
  )
}